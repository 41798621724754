<template>
  <div class="h-100">
    <vue-element-loading :active="loading" :text="loadingText" spinner="bar-fade-scale" color="var(--secondary)" />
    <video loop muted autoplay oncanplay="this.play()" onloadedmetadata="this.muted = true" class="background_video">
      <source src="../../assets/img/weather.mp4" type="video/mp4" />
      <source src="../../assets/img/weather.mp4" type="video/mp4" />
    </video>
    <vue-element-loading :active="loading" text="دریافت اطلاعات هواشناسی..." spinner="bar-fade-scale"
      color="var(--secondary)" />

    <div class="farm-current">
      <div class="farm-info">
        <img :src="currentFarm.farmImage"
          @error="() => (currentFarm.farmImage = require(`@/assets/img/farmImageMapBox.jpg`))"
          class="productImage rounded" alt="image" />
        <div class="farm-info-more">
          <img :src="productImage" class="productImage rounded" alt="image" />
          <ul>
            <li class="title">
              {{ currentFarm.title }}
              {{ currentFarm.id }}
            </li>
            <li>
              {{ currentFarm.stateName }} \ {{ currentFarm.cityName }} \
              {{ currentFarm.village }} \ {{ currentFarm.district }}
            </li>
          </ul>
        </div>
      </div>
      <div class="weather-info-list">
        <div class="weather-info-list-header">
          <img :src="weatherIcon(currentWeather.weatherIcon)" alt="" />
          <ul>
            <li>
              {{ currentWeather.weatherText }}
              <span v-if="currentWeather.hasPrecipitation">({{ currentWeather.precipitationType }})</span>
            </li>
            <li>
              {{ currentWeather.temperature.imperial.value
              }}<i>{{ currentWeather.temperature.imperial.unit }}</i>
            </li>
          </ul>
          <span>
            <svg height="30px" width="30px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" xml:space="preserve">
              <circle style="fill: #f4b2b0" cx="255.999" cy="381.469" r="59.943" />
              <g>
                <path style="fill: #b3404a" d="M256,455.991c-41.09,0-74.52-33.43-74.52-74.52s33.43-74.52,74.52-74.52s74.52,33.43,74.52,74.52

S297.09,455.991,256,455.991z M256,336.107c-25.014,0-45.364,20.35-45.364,45.364s20.35,45.364,45.364,45.364

c25.014,0,45.364-20.35,45.364-45.364C301.364,356.458,281.014,336.107,256,336.107z" />
                <path style="fill: #b3404a" d="M371.339,320.336c-3.777-7.108-12.602-9.808-19.713-6.032c-7.11,3.779-9.809,12.604-6.032,19.713

c7.706,14.499,11.779,30.909,11.779,47.455c0,27.531-10.849,53.289-30.549,72.528c-19.693,19.232-45.732,29.447-73.283,28.814

c-54.612-1.29-98.984-46.815-98.911-101.482c0.051-38.57,21.546-73.269,56.093-90.557c4.937-2.469,8.054-7.516,8.054-13.037V58.856

c0-16.377,13.324-29.701,29.701-29.701h15.044c16.377,0,29.701,13.324,29.701,29.701v209.796c0,8.051,6.526,14.578,14.578,14.578

s14.578-6.526,14.578-14.578v-17.46h35.084c8.051,0,14.578-6.526,14.578-14.578s-6.526-14.578-14.578-14.578h-35.084v-22.693

h13.789c8.051,0,14.578-6.526,14.578-14.578c0-8.051-6.526-14.578-14.578-14.578h-13.789v-22.693h35.084

c8.051,0,14.578-6.526,14.578-14.578s-6.526-14.578-14.578-14.578h-35.084V95.646h13.789c8.051,0,14.578-6.526,14.578-14.578

s-6.526-14.578-14.578-14.578h-13.789v-7.634C322.378,26.403,295.975,0,263.522,0h-15.044c-32.453,0-58.856,26.403-58.856,58.856

v210.229c-39.733,23.415-64.086,65.616-64.149,112.21c-0.095,70.388,57.047,129.007,127.377,130.667

c1.066,0.026,2.125,0.038,3.187,0.038c34.295-0.001,66.563-13.12,91.159-37.141c25.362-24.773,39.332-57.938,39.332-93.388

C386.527,360.172,381.275,339.032,371.339,320.336z" />
                <path style="fill: #b3404a" d="M256,283.699c-8.051,0-14.578-6.526-14.578-14.578v-83.578c0-8.051,6.526-14.578,14.578-14.578

c8.051,0,14.578,6.526,14.578,14.578v83.578C270.578,277.173,264.051,283.699,256,283.699z" />
              </g>
            </svg>
            {{ currentWeather.temperature.metric.value
            }}<i>{{ currentWeather.temperature.metric.unit }}</i>
          </span>
        </div>
        <div class="weather-info-list-item">
          <span>
            <svg fill="#fff" height="60px" width="60px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512.001 512.001" xml:space="preserve">
              <g>
                <g>
                  <path
                    d="M281.12,353.354V42.221C281.12,18.941,262.18,0,238.899,0c-23.282,0-42.221,18.941-42.221,42.221v311.133

c-26.391,15.093-42.646,42.761-42.756,73.36c-0.078,21.959,8.481,42.96,24.097,59.132c15.624,16.179,36.315,25.453,58.26,26.115

c0.886,0.026,1.767,0.04,2.649,0.04c22.227-0.001,43.14-8.461,59.142-23.987c16.642-16.149,25.806-37.809,25.806-60.992

C323.875,396.291,307.619,368.505,281.12,353.354z M286.905,476.506c-13.496,13.095-31.316,20.003-50.142,19.427

c-17.741-0.534-34.507-8.072-47.21-21.226c-12.701-13.152-19.661-30.176-19.597-47.937c0.093-26.181,14.773-49.723,38.31-61.438

c2.724-1.355,4.444-4.136,4.444-7.177V42.221c0-14.44,11.748-26.188,26.188-26.188c14.44,0,26.188,11.748,26.188,26.188v315.935

c0,3.042,1.721,5.821,4.444,7.177c23.632,11.762,38.311,35.4,38.311,61.689C307.842,445.831,300.407,463.405,286.905,476.506z" />
                </g>
              </g>
              <g>
                <g>
                  <path d="M246.915,376.889V93.528c0-4.427-3.589-8.017-8.017-8.017c-4.427,0-8.017,3.589-8.017,8.017v283.361

c-24.2,3.855-42.756,24.866-42.756,50.133c0,27.995,22.777,50.772,50.772,50.772c27.995,0,50.772-22.777,50.772-50.772

C289.671,401.755,271.115,380.744,246.915,376.889z M238.899,461.761c-19.155,0-34.739-15.584-34.739-34.739

c0-19.155,15.584-34.739,34.739-34.739s34.739,15.584,34.739,34.739C273.638,446.177,258.054,461.761,238.899,461.761z" />
                </g>
              </g>
              <g>
                <g>
                  <path d="M350.063,256.534h-42.756c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h42.756

c4.427,0,8.017-3.589,8.017-8.017S354.492,256.534,350.063,256.534z" />
                </g>
              </g>
              <g>
                <g>
                  <path d="M332.961,213.778h-25.653c-4.427,0-8.017,3.589-8.017,8.017c0,4.427,3.589,8.017,8.017,8.017h25.653

c4.427,0,8.017-3.589,8.017-8.017C340.978,217.368,337.388,213.778,332.961,213.778z" />
                </g>
              </g>
              <g>
                <g>
                  <path d="M350.063,171.023h-42.756c-4.427,0-8.017,3.589-8.017,8.017c0,4.427,3.589,8.017,8.017,8.017h42.756

c4.427,0,8.017-3.589,8.017-8.017C358.08,174.612,354.492,171.023,350.063,171.023z" />
                </g>
              </g>
              <g>
                <g>
                  <path d="M332.961,128.267h-25.653c-4.427,0-8.017,3.589-8.017,8.017c0,4.427,3.589,8.017,8.017,8.017h25.653

c4.427,0,8.017-3.589,8.017-8.017C340.978,131.856,337.388,128.267,332.961,128.267z" />
                </g>
              </g>
              <g>
                <g>
                  <path d="M350.063,85.511h-42.756c-4.427,0-8.017,3.589-8.017,8.017c0,4.427,3.589,8.017,8.017,8.017h42.756

c4.427,0,8.017-3.589,8.017-8.017C358.08,89.101,354.491,85.511,350.063,85.511z" />
                </g>
              </g>
            </svg>
          </span>
          <ul>
            <li>
              <h4>دمای احساسی</h4>
              <h3>
                {{ currentWeather.realFeelTemperature.metric.value
                }}{{ currentWeather.realFeelTemperature.metric.unit }}
              </h3>
            </li>
            <li>
              <h4>دمای احساسی در سایه</h4>
              <h3>
                {{ currentWeather.realFeelTemperatureShade.metric.value
                }}{{ currentWeather.realFeelTemperatureShade.metric.unit }}
              </h3>
            </li>
          </ul>
        </div>
        <div class="weather-info-list-item">
          <span>
            <svg fill="#fff" height="60px" width="60px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 328.611 328.611" xml:space="preserve">
              <g>
                <path
                  d="M209.306,50.798c-2.452-3.337-7.147-4.055-10.485-1.602c-3.338,2.453-4.055,7.147-1.603,10.485

c54.576,74.266,66.032,123.541,66.032,151.8c0,27.691-8.272,52.794-23.293,70.685c-17.519,20.866-42.972,31.446-75.651,31.446

c-73.031,0-98.944-55.018-98.944-102.131c0-52.227,28.103-103.234,51.679-136.829c25.858-36.847,52.11-61.415,52.37-61.657

c3.035-2.819,3.209-7.565,0.39-10.6c-2.819-3.034-7.565-3.209-10.599-0.39c-1.11,1.031-27.497,25.698-54.254,63.765

c-24.901,35.428-54.586,89.465-54.586,145.71c0,31.062,9.673,59.599,27.236,80.353c20.361,24.061,50.345,36.779,86.708,36.779

c36.794,0,66.926-12.726,87.139-36.801c17.286-20.588,26.806-49.117,26.806-80.33C278.25,156.216,240.758,93.597,209.306,50.798z" />
                <path
                  d="M198.43,148.146l-95.162,95.162c-2.929,2.929-2.929,7.678,0,10.606c1.465,1.464,3.385,2.197,5.304,2.197

s3.839-0.732,5.304-2.197l95.162-95.162c2.929-2.929,2.929-7.678,0-10.606C206.107,145.217,201.359,145.217,198.43,148.146z" />
                <path d="M191.965,207.899c-13.292,0-24.106,10.814-24.106,24.106s10.814,24.106,24.106,24.106s24.106-10.814,24.106-24.106

S205.257,207.899,191.965,207.899z M191.965,241.111c-5.021,0-9.106-4.085-9.106-9.106s4.085-9.106,9.106-9.106

s9.106,4.085,9.106,9.106S196.986,241.111,191.965,241.111z" />
                <path d="M125.178,194.162c13.292,0,24.106-10.814,24.106-24.106s-10.814-24.106-24.106-24.106s-24.106,10.814-24.106,24.106

S111.886,194.162,125.178,194.162z M125.178,160.949c5.021,0,9.106,4.085,9.106,9.106s-4.085,9.106-9.106,9.106

c-5.021,0-9.106-4.085-9.106-9.106S120.156,160.949,125.178,160.949z" />
              </g>
            </svg>
          </span>
          <ul>
            <li>
              <h4>رطوبت نسبی</h4>
              <h3>{{ currentWeather.relativeHumidity }} %</h3>
            </li>
            <li>
              <h4>رطوبت نسبی داخلی</h4>
              <h3>{{ currentWeather.indoorRelativeHumidity }} %</h3>
            </li>
          </ul>
        </div>
        <div class="weather-info-list-item">
          <span>
            <svg fill="#fff" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink" width="60px" height="60px" viewBox="0 0 560.777 560.777"
              xml:space="preserve">
              <g>
                <path d="M466.254,354.459c-52.122,0-94.526,42.405-94.526,94.519c0,52.122,42.404,94.526,94.526,94.526

s94.522-42.404,94.522-94.526C560.777,396.859,518.376,354.459,466.254,354.459z M482.86,394.727

c2.786-9.077,6.1-20.003,1.891-31.008c31.736,6.88,57.083,31.069,65.628,62.156c-3.784-0.032-7.719,0.01-11.821,0.061

c-17.366,0.201-37.057,0.416-50.105-5.675C477.283,414.8,478.753,408.121,482.86,394.727z M389.383,489.688l-0.191,0.153

c-6.492-12.199-10.193-26.103-10.193-40.869c0-24.092,9.82-45.929,25.665-61.741c3.752,1.036,8.513,1.708,13.524,2.418

c10.711,1.503,24.031,3.379,27.583,9.203c1.194,1.965,1.223,4.485,0.037,7.864c-2.049,6.776-9.353,8.993-17.819,11.564

c-11.826,3.594-26.546,8.07-25.337,28.567c0.583,7.342,6.501,14.175,12.773,21.408c9.572,11.052,15.532,18.893,11.248,26.91

c-1.741,3.355-4.896,5.302-9.376,5.769C407.458,501.977,394.457,495.849,389.383,489.688z M510.97,523.855

c-3.333-4.145-4.63-9.996-4.112-15.271c0.435-4.48,0.934-9.549-0.812-15.35c-2.927-8.396-11.159-13.685-19.121-18.795

c-6.474-4.14-12.573-8.06-14.179-12.647c-1.157-3.552-0.201-4.681,0.107-5.055c4.387-5.189,26.519-1.881,42.676,0.527

c16.008,2.395,29.986,4.476,37.388,1.741C549.744,486.557,533.704,510.228,510.97,523.855z M516.598,450.074

c-21.748-3.248-42.293-6.319-49.298,1.969c-2.576,3.048-3.071,7.08-1.447,12.069c2.465,7.033,9.927,11.817,17.143,16.442

c6.926,4.443,14.09,9.031,16.134,14.911c1.288,4.28,0.9,8.229,0.49,12.41c-0.598,6.146-1.256,13.091,4.821,19.536

c-11.547,5.643-24.498,8.82-38.187,8.82c-26.798,0-50.811-12.152-66.828-31.241c4.999,2.04,10.45,3.328,15.616,3.328

c1.018,0,2.021-0.047,3.011-0.149c6.958-0.732,12.167-4.065,15.056-9.614c6.936-12.993-3.234-24.712-12.19-35.06

c-5.227-6.025-10.632-12.26-11.023-17.156c-0.821-13.986,7.146-17.128,20.208-21.1c9.129-2.772,19.485-5.922,22.612-16.255

c1.881-5.335,1.629-10.016-0.742-13.908c-5.33-8.765-19.914-10.823-32.776-12.624c-2.777-0.393-5.47-0.771-7.878-1.195

c15.009-12.199,34.13-19.531,54.936-19.531c3.281,0,6.511,0.2,9.689,0.555c6.184,9.941,3.038,20.293-0.033,30.312

c-3.705,12.079-7.906,25.768,9.409,34.229c12.073,5.634,28.208,6.455,43.688,6.455c3.258,0,6.482-0.042,9.638-0.07

c4.699-0.056,9.175-0.098,13.403-0.032c0.943,5.134,1.461,10.407,1.461,15.803c0,0.909-0.028,1.815-0.061,2.711

C547.886,453.831,529.984,452.076,516.598,450.074z M252.598,189.903c8.501-53.2-27.867-103.413-81.072-111.921

c-53.203-8.499-103.416,27.867-111.917,81.067c-8.508,53.219,27.865,103.437,81.067,111.936

C193.877,279.492,244.09,243.122,252.598,189.903z M143.568,252.895c-43.235-6.917-72.78-47.707-65.871-90.947

c6.907-43.227,47.695-72.783,90.934-65.867c43.222,6.907,72.781,47.702,65.869,90.934

C227.589,230.259,186.794,259.802,143.568,252.895z M42.068,120.471c0,0,0,0-0.019,0c-0.458-0.07-0.922-0.214-1.36-0.443

l-23.434-11.98c-1.696-0.858-2.604-2.637-2.49-4.406c0.044-0.602,0.205-1.194,0.497-1.754c1.144-2.25,3.909-3.137,6.156-1.993

l23.457,11.985c2.24,1.152,3.139,3.916,1.981,6.165C45.94,119.85,43.982,120.774,42.068,120.471z M82.299,38.857

c-1.146-2.245-0.252-5.012,2.014-6.156c2.254-1.144,5.026-0.257,6.17,2.012l11.922,23.48c1.144,2.254,0.25,5.012-2.016,6.156

c-0.889,0.458-1.874,0.593-2.786,0.443c-1.396-0.219-2.672-1.087-3.365-2.45l-0.014-0.014L82.299,38.857z M242.844,86.686

c-1.783-1.797-1.773-4.695,0.019-6.483l18.628-18.598c1.792-1.792,4.707-1.778,6.485,0.019c1.783,1.783,1.773,4.681-0.023,6.473

L249.324,86.69c-1.081,1.078-2.562,1.503-3.958,1.274C244.431,87.82,243.56,87.4,242.844,86.686z M277.374,154.181

c-0.397-2.506,1.318-4.844,3.808-5.232l26.004-4.098c2.512-0.396,4.85,1.33,5.246,3.822c0.378,2.497-1.32,4.845-3.831,5.236

l-26.005,4.093c-0.483,0.08-0.971,0.075-1.428,0C279.261,157.691,277.684,156.188,277.374,154.181z M141.043,299.985l-4.156,25.995

c-0.396,2.502-2.74,4.201-5.248,3.8c-2.495-0.397-4.2-2.749-3.804-5.251l4.158-25.99c0.401-2.502,2.737-4.201,5.25-3.795

c0.616,0.094,1.195,0.312,1.71,0.63C140.467,296.321,141.339,298.114,141.043,299.985z M69.639,260.422

c0.889,0.887,1.34,2.072,1.33,3.249c0,1.167-0.445,2.348-1.349,3.234l-18.629,18.584c-1.076,1.083-2.562,1.503-3.953,1.283

c-0.936-0.153-1.816-0.573-2.532-1.292c-0.89-0.896-1.34-2.067-1.328-3.244c0-0.583,0.114-1.167,0.348-1.722

c0.21-0.55,0.556-1.064,1.003-1.512l18.629-18.598C64.965,258.612,67.861,258.635,69.639,260.422z M35.126,192.932

c0.38,2.492-1.321,4.845-3.83,5.232v0.005l-26.002,4.079c-0.483,0.075-0.971,0.075-1.428-0.004

c-1.907-0.303-3.505-1.797-3.815-3.808c-0.096-0.63-0.051-1.242,0.096-1.825c0.422-1.727,1.86-3.127,3.734-3.416l26.002-4.088

C32.396,188.713,34.744,190.439,35.126,192.932z M171.44,47.137v-0.019l4.151-25.986c0.401-2.506,2.739-4.205,5.253-3.799

c2.504,0.396,4.195,2.744,3.799,5.246l-4.161,26.01c-0.397,2.497-2.74,4.201-5.248,3.799

C172.745,51.986,171.039,49.639,171.44,47.137z M325.183,415.09c0.187,0.583,0.084,1.223-0.275,1.727

c-0.359,0.499-0.929,0.808-1.545,0.831l-19.952,0.593c-0.098,0-0.2,0-0.289-0.01c-0.957-0.117-1.713-0.91-1.736-1.899

c-0.032-1.092,0.821-2.002,1.914-2.035l14.435-0.425c-18.243-6.193-25.192-21.823-31.932-36.981

c-5.857-13.198-11.927-26.85-25.543-34.284c-6.959-4.094-14.237-6.604-21.268-9.026c-17.954-6.18-34.919-12.018-32.726-42.914

l3.937,0.285c-1.979,27.867,12.167,32.729,30.07,38.896c7.231,2.492,14.692,5.059,21.935,9.324

c14.734,8.042,21.077,22.309,27.202,36.114c6.729,15.153,13.129,29.51,30.145,35.008l-4.358-13.992

c-0.327-1.036,0.256-2.146,1.292-2.474c1.041-0.322,2.147,0.262,2.469,1.298L325.183,415.09z M371.597,367.84

c0.224,0.574,0.168,1.219-0.154,1.746c-0.322,0.522-0.868,0.872-1.484,0.933l-19.854,2.017c-0.149,0.014-0.299,0.014-0.438-0.005

c-0.9-0.107-1.639-0.826-1.727-1.76c-0.112-1.087,0.676-2.053,1.759-2.16l13.922-1.414c-2.529-1.783-5.399-2.997-8.392-4.267

c-5.357-2.268-10.902-4.615-14.743-10.463c-2.328-3.505-3.267-6.968-4.247-10.646c-0.471-1.746-0.961-3.585-1.643-5.517

c-2.482-7.075-4.635-7.444-10.375-8.438c-1.979-0.336-4.224-0.728-6.921-1.461c-13.609-3.724-15.317-9.343-17.292-15.854

c-1.04-3.454-2.23-7.365-5.53-11.757c-3.785-4.97-9.191-6.179-14.911-7.453c-6.926-1.549-14.081-3.146-18.071-11.588

c-2.049-4.2-3.12-8.209-4.056-11.738c-2.371-8.956-3.565-13.45-17.574-11.686l-0.497-3.92c17.429-2.18,19.499,5.586,21.889,14.594

c0.894,3.356,1.902,7.164,3.794,11.038c3.178,6.725,8.833,7.985,15.378,9.446c5.864,1.315,12.526,2.805,17.193,8.923

c3.729,4.966,5.083,9.423,6.165,13.003c1.844,6.048,3.053,10.043,14.548,13.188c2.516,0.677,4.662,1.051,6.553,1.382

c6.132,1.06,10.179,1.76,13.44,11.015c0.71,2.034,1.228,3.962,1.723,5.801c0.938,3.5,1.75,6.516,3.729,9.493

c3.188,4.858,7.953,6.87,12.989,9.007c3.066,1.298,6.193,2.638,9.063,4.645l-5.54-14.128c-0.396-1.013,0.107-2.16,1.12-2.553

c1.013-0.406,2.161,0.103,2.558,1.111L371.597,367.84z M275.107,214.746l-1.419-3.785l0.917-0.35

c0.151-0.056,3.78-1.405,7.756-0.924c1.988,0.233,3.741,0.887,5.213,1.928c3.104,2.226,4.807,6.044,5.068,11.364h0.056l0.065,0.994

c-0.103,5.026,1.046,8.429,3.407,10.118c0.919,0.653,2.044,1.06,3.355,1.213c1.815,0.215,3.604-0.098,4.635-0.34l0.131-0.056

c0.382-0.145,3.883-1.396,7.752-0.924c1.988,0.233,3.742,0.887,5.213,1.928c3.108,2.222,4.807,6.044,5.068,11.359h0.056

l0.065,1.003c-0.103,5.017,1.051,8.424,3.416,10.114c0.91,0.653,2.04,1.06,3.352,1.213c1.815,0.215,3.607-0.098,4.639-0.341

l0.117-0.056c0.154-0.056,3.78-1.404,7.757-0.924c1.987,0.233,3.747,0.887,5.218,1.928c3.107,2.226,4.807,6.048,5.068,11.383h0.051

l0.061,0.98c-0.088,5.027,1.051,8.429,3.417,10.114c0.919,0.653,2.044,1.06,3.355,1.208c1.815,0.215,3.603-0.098,4.634-0.34

l0.122-0.052c0.153-0.056,3.78-1.404,7.757-0.924c1.988,0.233,3.742,0.887,5.213,1.928c3.108,2.226,4.807,6.048,5.073,11.369h0.051

l0.065,0.993c-0.098,5.022,1.046,8.425,3.412,10.114c0.91,0.653,2.044,1.06,3.351,1.209c1.807,0.214,3.594-0.094,4.63-0.341

l0.135-0.057c0.388-0.14,3.889-1.395,7.748-0.929c1.992,0.233,3.747,0.887,5.218,1.933c3.108,2.227,4.812,6.049,5.073,11.388h0.047

l0.064,0.976c-0.023,1.357,0.075,2.558,0.243,3.658l3.271-13.361c0.257-1.055,1.325-1.708,2.385-1.451

c1.06,0.261,1.713,1.33,1.447,2.39l-4.971,20.302c-0.149,0.597-0.564,1.097-1.13,1.339c-0.569,0.247-1.218,0.22-1.754-0.084

l-17.395-9.786c-0.089-0.052-0.173-0.107-0.248-0.164c-0.756-0.593-0.993-1.661-0.508-2.524c0.536-0.952,1.745-1.284,2.692-0.752

l12.587,7.085l0.104,0.051c-0.388-1.395-0.631-2.936-0.719-4.652l-0.047,0.004l-0.07-0.998c0.098-5.036-1.045-8.447-3.412-10.118

c-2.128-1.508-5.898-1.368-7.989-0.863l-0.117,0.046c-0.153,0.057-3.78,1.405-7.761,0.929c-1.984-0.237-3.743-0.891-5.214-1.932

c-0.191-0.14-0.378-0.275-0.56-0.42c-2.758-2.264-4.275-5.946-4.518-10.958h-0.047l-0.07-0.98

c0.103-5.036-1.045-8.443-3.406-10.113c-0.915-0.649-2.049-1.06-3.36-1.214c-1.815-0.215-3.612,0.098-4.645,0.35l-0.106,0.052

c-0.154,0.056-3.781,1.4-7.757,0.929c-1.983-0.243-3.743-0.892-5.218-1.932c-0.196-0.14-0.393-0.294-0.565-0.434

c-2.744-2.25-4.261-5.918-4.503-10.93h-0.047l-0.075-0.999c0.103-5.036-1.04-8.443-3.402-10.119

c-2.138-1.512-5.899-1.367-7.989-0.868l-0.136,0.051c-0.383,0.145-3.883,1.391-7.747,0.924c-1.984-0.238-3.743-0.887-5.218-1.927

c-0.201-0.145-0.388-0.29-0.565-0.434c-2.753-2.259-4.266-5.937-4.508-10.954h-0.047l-0.074-0.98

c0.106-5.04-1.041-8.442-3.402-10.113c-0.915-0.649-2.045-1.055-3.361-1.213c-1.811-0.215-3.607,0.098-4.634,0.345l-0.126,0.051

c-0.388,0.145-3.883,1.391-7.752,0.929c-1.979-0.243-3.738-0.891-5.213-1.932c-0.201-0.145-0.393-0.294-0.574-0.434

c-2.74-2.25-4.257-5.932-4.494-10.949l-0.047-0.005l-0.08-0.98c0.107-5.041-1.04-8.443-3.399-10.114

c-0.919-0.648-2.047-1.055-3.355-1.213c-2.969-0.354-5.82,0.686-5.851,0.691L275.107,214.746z" />
              </g>
            </svg>
          </span>
          <ul>
            <li>
              <h4>شاخص فرابنفش</h4>
              <h3>{{ currentWeather.uvIndexText }}</h3>
            </li>
            <li>
              <h4>دید افقی</h4>
              <h3>
                {{ currentWeather.visibility.metric.value }}
                {{ currentWeather.visibility.metric.unit }}
              </h3>
            </li>
          </ul>
        </div>
        <div class="weather-info-list-item">
          <span>
            <svg fill="#fff" height="60px" width="60px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" xml:space="preserve">
              <g>
                <g>
                  <g>
                    <path
                      d="M490.407,465.492H350.169l-14.921-26.25c-1.437-2.529-4.121-4.09-7.029-4.09h-45.811v-57.581

c92.149-12.892,163.301-92.217,163.301-187.862C445.709,85.103,360.606,0,256,0S66.291,85.103,66.291,189.709

c0,95.645,71.152,174.97,163.301,187.862v57.581h-45.811c-2.907,0-5.592,1.561-7.029,4.09l-14.921,26.25H21.593

C9.687,465.492,0,475.18,0,487.085v3.32C0,502.312,9.687,512,21.593,512h371.025c4.466,0,8.084-3.618,8.084-8.084

c0-4.466-3.618-8.084-8.084-8.084H21.593c-2.991,0-5.425-2.434-5.425-5.425v-3.32c0-2.991,2.434-5.425,5.425-5.425h144.941

h178.931h144.941c2.991,0,5.425,2.434,5.425,5.425v3.32c0,2.991-2.434,5.425-5.425,5.425h-70.48

c-4.466,0-8.084,3.619-8.084,8.084c0,4.466,3.619,8.084,8.084,8.084h70.48c11.906,0,21.593-9.687,21.593-21.593v-3.32

C512,475.18,502.313,465.492,490.407,465.492z M82.459,189.709C82.459,94.018,160.31,16.168,256,16.168

s173.541,77.85,173.541,173.541c0,89.942-68.779,164.115-156.502,172.704c-11.29,1.104-22.789,1.104-34.079,0

C151.238,353.824,82.459,279.651,82.459,189.709z M266.24,379.143v56.011h-20.48v-56.011c0.039,0.002,0.079,0.002,0.117,0.004

c1.494,0.079,2.993,0.137,4.496,0.181c0.184,0.005,0.369,0.016,0.553,0.022c1.686,0.044,3.377,0.069,5.074,0.069

s3.388-0.025,5.074-0.069c0.184-0.005,0.369-0.015,0.553-0.022c1.503-0.044,3.002-0.102,4.496-0.181

C266.161,379.145,266.201,379.145,266.24,379.143z M180.43,465.492l8.055-14.171h49.191h36.648h49.192l8.055,14.171H180.43z" />
                    <path d="M383.137,282.366c2.633-3.606,1.848-8.663-1.758-11.296c-3.606-2.634-8.663-1.846-11.296,1.758

c-26.577,36.37-69.225,58.086-114.082,58.086c-75.491,0-137.335-59.549-141.027-134.141h21.979c4.466,0,8.084-3.62,8.084-8.084

c0-3.539-2.276-6.539-5.442-7.634c1.777-26.632,12.285-50.642,28.688-69.284c1.115,0.543,2.325,0.822,3.538,0.822

c2.068,0,4.138-0.789,5.717-2.368c2.496-2.496,3.011-6.218,1.558-9.228c18.747-16.364,42.828-26.757,69.381-28.419

c1.176,3.011,4.098,5.146,7.525,5.146c3.423,0,6.342-2.13,7.522-5.135c26.239,1.719,50.327,12.353,69.162,28.891

c-1.176,2.917-0.583,6.379,1.781,8.743c1.579,1.578,3.649,2.368,5.717,2.368c1.041,0,2.083-0.202,3.061-0.602

c16.609,18.736,27.357,42.76,29.161,69.066c-3.163,1.097-5.437,4.095-5.437,7.632c0,4.465,3.618,8.084,8.084,8.084h21.973

c-0.913,18.707-5.453,36.725-13.549,53.651c-1.926,4.028-0.223,8.855,3.804,10.781c4.025,1.927,8.854,0.224,10.781-3.804

c10.16-21.242,15.313-44.017,15.313-67.691c0-86.776-70.597-157.373-157.373-157.373S98.627,102.934,98.627,189.709

S169.224,347.082,256,347.082C305.999,347.082,353.527,322.89,383.137,282.366z M360.867,95.256

c20.671,22.926,33.943,52.64,36.035,85.349h-8.324c-1.86-29.701-13.512-57.573-33.289-79.773L360.867,95.256z M264.084,48.742

c32.599,1.85,62.271,14.807,85.288,35.14l-5.536,5.536c-22.29-19.705-50.156-31.221-79.752-33V48.742z M247.916,48.742v7.677

c-29.589,1.78-57.461,13.298-79.751,33.002l-5.538-5.538C185.644,63.551,215.316,50.592,247.916,48.742z M151.133,95.256

l5.577,5.577c-19.766,22.193-31.427,50.068-33.289,79.773h-8.323C117.19,147.897,130.462,118.183,151.133,95.256z" />
                    <path d="M316.036,129.606c-2.942-2.398-7.149-2.431-10.125-0.08l-67.249,53.143c-2.839,2.247-5.161,4.984-6.898,8.133

c-0.047,0.087-0.094,0.175-0.139,0.263l-0.327,0.646c-4.251,8.432-3.834,18.276,1.118,26.333

c0.036,0.059,0.077,0.127,0.102,0.168c0.112,0.179,0.227,0.355,0.345,0.529l0.086,0.128c0.12,0.183,0.244,0.367,0.372,0.548

c0.071,0.102,0.151,0.212,0.251,0.349c0.189,0.259,0.38,0.516,0.579,0.77c0.057,0.073,0.119,0.152,0.198,0.249

c0.237,0.294,0.477,0.583,0.723,0.863l0.019,0.022c0.055,0.065,0.11,0.127,0.165,0.19c0.235,0.26,0.47,0.516,0.703,0.759

l0.216,0.22c0.222,0.226,0.446,0.447,0.675,0.664c0.047,0.045,0.095,0.089,0.143,0.134l0.244,0.223

c0.195,0.178,0.389,0.351,0.591,0.525c0.032,0.028,0.116,0.099,0.151,0.127c0.141,0.117,0.284,0.236,0.429,0.352l0.378,0.305

c0.035,0.028,0.07,0.055,0.106,0.082c0.191,0.146,0.383,0.29,0.637,0.475l0.334,0.239c0.262,0.185,0.532,0.366,0.806,0.547

c0.04,0.026,0.058,0.038,0.098,0.064c0.037,0.024,0.16,0.105,0.197,0.127c0.3,0.19,0.607,0.375,0.99,0.596l0.12,0.068

c0.315,0.181,0.638,0.354,0.961,0.521c0.114,0.061,0.227,0.12,0.342,0.176c0.275,0.137,0.555,0.267,0.834,0.397

c0.044,0.02,0.069,0.031,0.113,0.052l0.246,0.111c0.234,0.105,0.471,0.201,0.803,0.334c0.181,0.075,0.363,0.149,0.53,0.212

c0.105,0.04,0.21,0.078,0.318,0.114c2.963,1.07,6.064,1.614,9.218,1.614c6.047,0,11.993-2.068,16.729-5.813l0.564-0.444

c0.084-0.066,0.166-0.134,0.247-0.203c2.737-2.336,4.952-5.159,6.583-8.394l38.581-76.534

C319.85,136.122,318.974,132.004,316.036,129.606z M265.125,208.762c-0.637,1.263-1.491,2.368-2.538,3.285l-0.433,0.342

c-1.934,1.53-4.256,2.338-6.714,2.338c-1.272,0-2.521-0.218-3.714-0.649c-0.046-0.017-0.093-0.035-0.14-0.051l-0.236-0.097

c-0.019-0.008-0.092-0.037-0.111-0.044l-0.261-0.112c-0.128-0.059-0.257-0.116-0.383-0.179c-0.036-0.019-0.072-0.039-0.11-0.057

c-0.122-0.063-0.245-0.125-0.363-0.193c-0.03-0.018-0.063-0.036-0.093-0.053c-0.013-0.008-0.027-0.016-0.042-0.024

c-0.124-0.072-0.247-0.146-0.346-0.208l-0.123-0.08c-0.11-0.071-0.218-0.142-0.26-0.171l-0.686-0.517

c-0.028-0.024-0.061-0.05-0.089-0.072l-0.511-0.451c-0.084-0.081-0.167-0.163-0.249-0.247c-0.015-0.015-0.107-0.107-0.121-0.122

c-0.098-0.101-0.193-0.206-0.288-0.312c-0.027-0.032-0.056-0.066-0.084-0.098c-0.095-0.108-0.187-0.22-0.305-0.367

c-0.017-0.022-0.033-0.043-0.051-0.065c-0.082-0.104-0.158-0.21-0.184-0.244l-0.117-0.162c-0.015-0.021-0.029-0.042-0.049-0.068

l-0.245-0.366c0,0-0.022-0.032-0.039-0.059c-0.015-0.024-0.03-0.048-0.045-0.073c-1.996-3.245-2.168-7.205-0.46-10.589

l0.247-0.492c0.689-1.213,1.601-2.274,2.711-3.152l38.551-30.465L265.125,208.762z" />
                    <path d="M300.936,260.155c0-4.465-3.618-8.084-8.084-8.084h-73.297c-4.466,0-8.084,3.62-8.084,8.084

c0,4.465,3.619,8.084,8.084,8.084h73.297C297.318,268.239,300.936,264.621,300.936,260.155z" />
                    <path d="M237.676,280.301c-4.466,0-8.084,3.618-8.084,8.084c0,4.466,3.618,8.084,8.084,8.084h36.648

c4.466,0,8.084-3.619,8.084-8.084c0-4.466-3.619-8.084-8.084-8.084H237.676z" />
                  </g>
                </g>
              </g>
            </svg>
          </span>
          <ul>
            <li>
              <h4>فشار هوا</h4>
              <h3>
                {{ currentWeather.pressure.metric.value }}
                {{ currentWeather.pressure.metric.unit }}
              </h3>
            </li>
            <li>
              <h4>نقطه شبنم</h4>
              <h3>
                {{ currentWeather.dewPoint.metric.value }}
                {{ currentWeather.dewPoint.metric.unit }}
              </h3>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="weather-info">
      <div class="weather-info-box" v-show="courrentDetailForm == 'windchart'">
        <h4 class="weather-info-box-header">سرعت و جهت باد در محل زمین {{ currentFarm.title }}</h4>
        <div id="windchart"></div>
      </div>
      <div class="weather-info-box" v-show="courrentDetailForm == 'historical'">
        <h4 class="weather-info-box-header">وضعیت هوا در بیست و چهار ساعت گذشته</h4>

      </div>
      <div class="weather-info-box" v-show="courrentDetailForm == 'forecasts'">
        <h4 class="weather-info-box-header">پیش بینی 12 ساعت آینده</h4>
        <div class="huorly-forecasts-info">
          <div class="huorly-forecasts-info-header">
            <img :src="weatherIcon(hourlyForcast.weatherIcon)" alt="" />
            <ul>
              <li>
                <h4 style="direction: ltr;">{{ getPersion(hourlyForcast.dateTime, "HH:mm") }}</h4>
                {{ hourlyForcast.iconPhrase }}
              </li>
            </ul>
            <span>
              <svg height="30px" width="30px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" xml:space="preserve">
                <circle style="fill: #f4b2b0" cx="255.999" cy="381.469" r="59.943" />
                <g>
                  <path style="fill: #b3404a" d="M256,455.991c-41.09,0-74.52-33.43-74.52-74.52s33.43-74.52,74.52-74.52s74.52,33.43,74.52,74.52

S297.09,455.991,256,455.991z M256,336.107c-25.014,0-45.364,20.35-45.364,45.364s20.35,45.364,45.364,45.364

c25.014,0,45.364-20.35,45.364-45.364C301.364,356.458,281.014,336.107,256,336.107z" />
                  <path style="fill: #b3404a" d="M371.339,320.336c-3.777-7.108-12.602-9.808-19.713-6.032c-7.11,3.779-9.809,12.604-6.032,19.713

c7.706,14.499,11.779,30.909,11.779,47.455c0,27.531-10.849,53.289-30.549,72.528c-19.693,19.232-45.732,29.447-73.283,28.814

c-54.612-1.29-98.984-46.815-98.911-101.482c0.051-38.57,21.546-73.269,56.093-90.557c4.937-2.469,8.054-7.516,8.054-13.037V58.856

c0-16.377,13.324-29.701,29.701-29.701h15.044c16.377,0,29.701,13.324,29.701,29.701v209.796c0,8.051,6.526,14.578,14.578,14.578

s14.578-6.526,14.578-14.578v-17.46h35.084c8.051,0,14.578-6.526,14.578-14.578s-6.526-14.578-14.578-14.578h-35.084v-22.693

h13.789c8.051,0,14.578-6.526,14.578-14.578c0-8.051-6.526-14.578-14.578-14.578h-13.789v-22.693h35.084

c8.051,0,14.578-6.526,14.578-14.578s-6.526-14.578-14.578-14.578h-35.084V95.646h13.789c8.051,0,14.578-6.526,14.578-14.578

s-6.526-14.578-14.578-14.578h-13.789v-7.634C322.378,26.403,295.975,0,263.522,0h-15.044c-32.453,0-58.856,26.403-58.856,58.856

v210.229c-39.733,23.415-64.086,65.616-64.149,112.21c-0.095,70.388,57.047,129.007,127.377,130.667

c1.066,0.026,2.125,0.038,3.187,0.038c34.295-0.001,66.563-13.12,91.159-37.141c25.362-24.773,39.332-57.938,39.332-93.388

C386.527,360.172,381.275,339.032,371.339,320.336z" />
                  <path style="fill: #b3404a" d="M256,283.699c-8.051,0-14.578-6.526-14.578-14.578v-83.578c0-8.051,6.526-14.578,14.578-14.578

c8.051,0,14.578,6.526,14.578,14.578v83.578C270.578,277.173,264.051,283.699,256,283.699z" />
                </g>
              </svg>
              {{ hourlyForcast.temperature.value
              }}<i>{{ hourlyForcast.temperature.unit }}</i>
            </span>
          </div>
          <ul class="huorly-forecasts-info-bod">
            <li>
              <h4>دمایی که احساس می شود :</h4>
              <h3>{{ hourlyForcast.realFeelTemperature.value }} {{ hourlyForcast.realFeelTemperature.unit }}
              </h3>
            </li>
            <li>
              <h4>دمای هوا در سایه :</h4>
              <h3>{{ hourlyForcast.realFeelTemperatureShade.value }} {{
                hourlyForcast.realFeelTemperatureShade.unit }}</h3>
            </li>
            <li>
              <h4>رطوبت نسبی :</h4>
              <h3>{{ hourlyForcast.relativeHumidity }}</h3>
            </li>
            <li>
              <h4>رطوبت نسبی داخلی:</h4>
              <h3>{{ hourlyForcast.indoorRelativeHumidity }}</h3>
            </li>
            <li>
              <h4>نقطه شبنم :</h4>
              <h3>{{ hourlyForcast.dewPoint.value }} {{ hourlyForcast.dewPoint.unit }}</h3>
            </li>
            <li>
              <h4>سرعت باد :</h4>
              <h3>{{ hourlyForcast.wind.speed.value }} {{ hourlyForcast.wind.speed.unit }}</h3>
            </li>
            <li>
              <h4>سرعت تندباد ناگهانی :</h4>
              <h3>{{ hourlyForcast.windGust.speed.value }} {{ hourlyForcast.windGust.speed.unit }}
              </h3>
            </li>
            <li>
              <h4>شاخص اشعه فرابنفش</h4>
              <h3>{{ hourlyForcast.uvIndex }} {{ hourlyForcast.uvIndexText }}</h3>
            </li>
            <li style="border-bottom: none;">
              <h4>دید افقی :</h4>
              <h3>{{ hourlyForcast.visibility.value }} {{ hourlyForcast.visibility.unit }}</h3>
            </li>
            <li>
              <h4>مقدار ابرناکی :</h4>
              <h3>{{ hourlyForcast.cloudCover }}</h3>
            </li>
          </ul>
        </div>
        <div class="huorly-forecasts-date">
          <ul>
            <li @click="activeHourlyForcast(0)" :class="hourlyForcastActiveIndex == 0 ? 'active' : ''">
              <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M3 5.5L5 3.5M21 5.5L19 3.5M12 8.5V13.5M12 16.5H12.01M20 12.5C20 16.9183 16.4183 20.5 12 20.5C7.58172 20.5 4 16.9183 4 12.5C4 8.08172 7.58172 4.5 12 4.5C16.4183 4.5 20 8.08172 20 12.5Z"
                  stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <h4>{{ getPersion(hourlyForcastList[0]?.dateTime, "HH:mm") }}</h4>
            </li>
            <li @click="activeHourlyForcast(1)" :class="hourlyForcastActiveIndex == 1 ? 'active' : ''">
              <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M3 5.5L5 3.5M21 5.5L19 3.5M12 8.5V13.5M12 16.5H12.01M20 12.5C20 16.9183 16.4183 20.5 12 20.5C7.58172 20.5 4 16.9183 4 12.5C4 8.08172 7.58172 4.5 12 4.5C16.4183 4.5 20 8.08172 20 12.5Z"
                  stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <h4>{{ getPersion(hourlyForcastList[1]?.dateTime, "HH:mm") }}</h4>
            </li>
            <li @click="activeHourlyForcast(2)" :class="hourlyForcastActiveIndex == 2 ? 'active' : ''">
              <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M3 5.5L5 3.5M21 5.5L19 3.5M12 8.5V13.5M12 16.5H12.01M20 12.5C20 16.9183 16.4183 20.5 12 20.5C7.58172 20.5 4 16.9183 4 12.5C4 8.08172 7.58172 4.5 12 4.5C16.4183 4.5 20 8.08172 20 12.5Z"
                  stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <h4>{{ getPersion(hourlyForcastList[2]?.dateTime, "HH:mm") }}</h4>
            </li>
            <li @click="activeHourlyForcast(3)" :class="hourlyForcastActiveIndex == 3 ? 'active' : ''">
              <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M3 5.5L5 3.5M21 5.5L19 3.5M12 8.5V13.5M12 16.5H12.01M20 12.5C20 16.9183 16.4183 20.5 12 20.5C7.58172 20.5 4 16.9183 4 12.5C4 8.08172 7.58172 4.5 12 4.5C16.4183 4.5 20 8.08172 20 12.5Z"
                  stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <h4>{{ getPersion(hourlyForcastList[3]?.dateTime, "HH:mm") }}</h4>
            </li>
            <li @click="activeHourlyForcast(4)" :class="hourlyForcastActiveIndex == 4 ? 'active' : ''">
              <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M3 5.5L5 3.5M21 5.5L19 3.5M12 8.5V13.5M12 16.5H12.01M20 12.5C20 16.9183 16.4183 20.5 12 20.5C7.58172 20.5 4 16.9183 4 12.5C4 8.08172 7.58172 4.5 12 4.5C16.4183 4.5 20 8.08172 20 12.5Z"
                  stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <h4>{{ getPersion(hourlyForcastList[4]?.dateTime, "HH:mm") }}</h4>
            </li>
            <li @click="activeHourlyForcast(5)" :class="hourlyForcastActiveIndex == 5 ? 'active' : ''">
              <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M3 5.5L5 3.5M21 5.5L19 3.5M12 8.5V13.5M12 16.5H12.01M20 12.5C20 16.9183 16.4183 20.5 12 20.5C7.58172 20.5 4 16.9183 4 12.5C4 8.08172 7.58172 4.5 12 4.5C16.4183 4.5 20 8.08172 20 12.5Z"
                  stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <h4>{{ getPersion(hourlyForcastList[5]?.dateTime, "HH:mm") }}</h4>
            </li>
          </ul>
          <ul>
            <li @click="activeHourlyForcast(6)" :class="hourlyForcastActiveIndex == 6 ? 'active' : ''">
              <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M3 5.5L5 3.5M21 5.5L19 3.5M12 8.5V13.5M12 16.5H12.01M20 12.5C20 16.9183 16.4183 20.5 12 20.5C7.58172 20.5 4 16.9183 4 12.5C4 8.08172 7.58172 4.5 12 4.5C16.4183 4.5 20 8.08172 20 12.5Z"
                  stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <h4>{{ getPersion(hourlyForcastList[6]?.dateTime, "HH:mm") }}</h4>
            </li>
            <li @click="activeHourlyForcast(7)" :class="hourlyForcastActiveIndex == 7 ? 'active' : ''">
              <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M3 5.5L5 3.5M21 5.5L19 3.5M12 8.5V13.5M12 16.5H12.01M20 12.5C20 16.9183 16.4183 20.5 12 20.5C7.58172 20.5 4 16.9183 4 12.5C4 8.08172 7.58172 4.5 12 4.5C16.4183 4.5 20 8.08172 20 12.5Z"
                  stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <h4>{{ getPersion(hourlyForcastList[7]?.dateTime, "HH:mm") }}</h4>
            </li>
            <li @click="activeHourlyForcast(8)" :class="hourlyForcastActiveIndex == 8 ? 'active' : ''">
              <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M3 5.5L5 3.5M21 5.5L19 3.5M12 8.5V13.5M12 16.5H12.01M20 12.5C20 16.9183 16.4183 20.5 12 20.5C7.58172 20.5 4 16.9183 4 12.5C4 8.08172 7.58172 4.5 12 4.5C16.4183 4.5 20 8.08172 20 12.5Z"
                  stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <h4>{{ getPersion(hourlyForcastList[8]?.dateTime, "HH:mm") }}</h4>
            </li>
            <li @click="activeHourlyForcast(9)" :class="hourlyForcastActiveIndex == 9 ? 'active' : ''">
              <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M3 5.5L5 3.5M21 5.5L19 3.5M12 8.5V13.5M12 16.5H12.01M20 12.5C20 16.9183 16.4183 20.5 12 20.5C7.58172 20.5 4 16.9183 4 12.5C4 8.08172 7.58172 4.5 12 4.5C16.4183 4.5 20 8.08172 20 12.5Z"
                  stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <h4>{{ getPersion(hourlyForcastList[9]?.dateTime, "HH:mm") }}</h4>
            </li>
            <li @click="activeHourlyForcast(10)" :class="hourlyForcastActiveIndex == 10 ? 'active' : ''">
              <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M3 5.5L5 3.5M21 5.5L19 3.5M12 8.5V13.5M12 16.5H12.01M20 12.5C20 16.9183 16.4183 20.5 12 20.5C7.58172 20.5 4 16.9183 4 12.5C4 8.08172 7.58172 4.5 12 4.5C16.4183 4.5 20 8.08172 20 12.5Z"
                  stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <h4>{{ getPersion(hourlyForcastList[10]?.dateTime, "HH:mm") }}</h4>
            </li>
            <li @click="activeHourlyForcast(11)" :class="hourlyForcastActiveIndex == 11 ? 'active' : ''">
              <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M3 5.5L5 3.5M21 5.5L19 3.5M12 8.5V13.5M12 16.5H12.01M20 12.5C20 16.9183 16.4183 20.5 12 20.5C7.58172 20.5 4 16.9183 4 12.5C4 8.08172 7.58172 4.5 12 4.5C16.4183 4.5 20 8.08172 20 12.5Z"
                  stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <h4>{{ getPersion(hourlyForcastList[11]?.dateTime, "HH:mm") }}</h4>
            </li>
          </ul>
        </div>
      </div>
      <div class="weather-info-box" v-show="courrentDetailForm == 'today'">
        <h4 class="weather-info-box-header">وضعیت هوای امروز و امشب</h4>
        <div class="daily-box">
          <div class="daily-info">
            <div class="daily-info-header">
              <h4>{{ getPersion(dailyForecast.date, "YYYY-MM-DD HH:mm") }}</h4>
              <ul>
                <li style="border-bottom: 1px dashed #ffffff42;">
                  <h5>کمترین دما</h5>
                  <i>{{ dailyForecast.temperature.minimum.value }} {{ dailyForecast.temperature.minimum.unit }}</i>
                </li>
                <li>
                  <h5>بیشترین دما</h5>
                  <i>{{ dailyForecast.temperature.maximum.value }} {{ dailyForecast.temperature.maximum.unit }}</i>
                </li>
              </ul>
            </div>
            <ul class="daily-info-body">
              <li>
                <h5>حداقل دمایی که احساس می شود</h5>
                <i>{{ dailyForecast.realFeelTemperature.minimum.value }} {{
                  dailyForecast.realFeelTemperature.minimum.unit }}</i>
              </li>
              <li>
                <h5>حداکثر دمایی که احساس می شود</h5>
                <i>{{ dailyForecast.realFeelTemperature.maximum.value }} {{
                  dailyForecast.realFeelTemperature.maximum.unit }}</i>
              </li>
              <li>
                <h5>حداقل دمایی که در سایه احساس می شود</h5>
                <i>{{ dailyForecast.realFeelTemperatureShade.minimum.value }} {{
                  dailyForecast.realFeelTemperatureShade.minimum.unit }}</i>
              </li>
              <li>
                <h5>حداکثر دمایی که در سایه احساس می شود</h5>
                <i>{{ dailyForecast.realFeelTemperatureShade.maximum.value }} {{
                  dailyForecast.realFeelTemperatureShade.maximum.unit }}</i>
              </li>
              <li style="border:none">
                <h5>تعداد ساعات آفتابی</h5>
                <i>{{ dailyForecast.hoursOfSun }}</i>
              </li>
              <li>
                <h5>شاخص فرابنفش</h5>
                <i>{{ dailyForecast.airAndPollen[5].category }} {{ dailyForecast.airAndPollen[5].value }}</i>
              </li>
            </ul>
          </div>
          <div class="daily-detail">
            <div class="today">
              <span> <svg width="30px" height="30px" viewBox="0 0 24 24" fill="white"
                  xmlns="http://www.w3.org/2000/svg">
                  <rect width="24" height="24" fill="transparent" />
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12Z"
                    fill="#323232" />
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M12 2C12.5523 2 13 2.44772 13 3V5C13 5.55228 12.5523 6 12 6C11.4477 6 11 5.55228 11 5V3C11 2.44772 11.4477 2 12 2Z"
                    fill="#323232" />
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M19.7071 4.29289C20.0976 4.68342 20.0976 5.31658 19.7071 5.70711L17.7071 7.70711C17.3166 8.09763 16.6834 8.09763 16.2929 7.70711C15.9024 7.31658 15.9024 6.68342 16.2929 6.29289L18.2929 4.29289C18.6834 3.90237 19.3166 3.90237 19.7071 4.29289Z"
                    fill="#323232" />
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M18 12C18 11.4477 18.4477 11 19 11H21C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13H19C18.4477 13 18 12.5523 18 12Z"
                    fill="#323232" />
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M16.2929 16.2929C16.6834 15.9024 17.3166 15.9024 17.7071 16.2929L19.7071 18.2929C20.0976 18.6834 20.0976 19.3166 19.7071 19.7071C19.3166 20.0976 18.6834 20.0976 18.2929 19.7071L16.2929 17.7071C15.9024 17.3166 15.9024 16.6834 16.2929 16.2929Z"
                    fill="#323232" />
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M12 18C12.5523 18 13 18.4477 13 19V21C13 21.5523 12.5523 22 12 22C11.4477 22 11 21.5523 11 21V19C11 18.4477 11.4477 18 12 18Z"
                    fill="#323232" />
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M7.70711 16.2929C8.09763 16.6834 8.09763 17.3166 7.70711 17.7071L5.70711 19.7071C5.31658 20.0976 4.68342 20.0976 4.29289 19.7071C3.90237 19.3166 3.90237 18.6834 4.29289 18.2929L6.29289 16.2929C6.68342 15.9024 7.31658 15.9024 7.70711 16.2929Z"
                    fill="#323232" />
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M2 12C2 11.4477 2.44772 11 3 11H5C5.55228 11 6 11.4477 6 12C6 12.5523 5.55228 13 5 13H3C2.44772 13 2 12.5523 2 12Z"
                    fill="#323232" />
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M4.29289 4.29289C4.68342 3.90237 5.31658 3.90237 5.70711 4.29289L7.70711 6.29289C8.09763 6.68342 8.09763 7.31658 7.70711 7.70711C7.31658 8.09763 6.68342 8.09763 6.29289 7.70711L4.29289 5.70711C3.90237 5.31658 3.90237 4.68342 4.29289 4.29289Z"
                    fill="#323232" />
                </svg></span>
              <div class="daily-detail-box">
                <div class="daily-detail-box-header">
                  <img :src="weatherIcon(dailyForecast.day.icon)" alt="" />
                  <h4>{{ dailyForecast.day.iconPhrase }} <small>{{ dailyForecast.day.longPhrase }}</small></h4>

                </div>
                <ul>
                  <li v-if="dailyForecast.day.precipitationProbability > 0">
                    <h4>احتمال بارش : </h4>
                    <h3>{{ dailyForecast.day.precipitationProbability }} %</h3>
                  </li>
                  <li v-if="dailyForecast.day.precipitationProbability > 0">
                    <h4>شدت بارش : </h4>
                    <h3>{{ dailyForecast.day.precipitationIntensity }}</h3>
                  </li>
                  <li v-if="dailyForecast.day.precipitationProbability > 0">
                    <h4>تعداد ساعات بارش :</h4>
                    <h3>{{ dailyForecast.day.hoursOfPrecipitation }}</h3>
                  </li>
                  <li v-if="dailyForecast.day.thunderstormProbability > 0">
                    <h4>حتمال طوفان رعد و برق</h4>
                    <h3>{{ dailyForecast.day.thunderstormProbability }}</h3>
                  </li>
                  <li>
                    <h4>سرعت باد : </h4>
                    <h3>{{ dailyForecast.day.wind.speed.value }} {{ dailyForecast.day.wind.speed.unit }}</h3>
                  </li>
                  <li>
                    <h4>جهت باد : </h4>
                    <h3>{{ dailyForecast.day.wind.direction.degrees }}</h3>
                  </li>
                  <li>
                    <h4>درصد ابرناکی : </h4>
                    <h3>{{ dailyForecast.day.cloudCover }} %</h3>
                  </li>
                  <li>
                    <h4>میانگین رطوبت نسبی : </h4>
                    <h3>{{ dailyForecast.day.relativeHumidity.average }} %</h3>
                  </li>
                  <li>
                    <h4>حداقل رطوبت نسبی : </h4>
                    <h3>{{ dailyForecast.day.relativeHumidity.minimum }} %</h3>
                  </li>
                  <li>
                    <h4> حداکثر رطوبت نسبی : </h4>
                    <h3>{{ dailyForecast.day.relativeHumidity.maximum }} %</h3>
                  </li>
                  <li>
                    <h4>شدت تابش خورشید : </h4>
                    <h3>{{ dailyForecast.day.solarIrradiance.value }} {{ dailyForecast.day.solarIrradiance.unit }}</h3>
                  </li>
                  <li>
                    <h4>تبخیر و تعرق : </h4>
                    <h3>{{ dailyForecast.day.evapotranspiration.value }} {{ dailyForecast.day.evapotranspiration.unit }}
                    </h3>
                  </li>
                  <li>
                    <h4>سرعت تند باد ناگهانی : </h4>
                    <h3>{{ dailyForecast.day.windGust.speed.value }} {{ dailyForecast.day.windGust.speed.unit }}</h3>
                  </li>
                  <li v-if="dailyForecast.day.rainProbability > 0">
                    <h4>احتمال بارش باران : </h4>
                    <h3>{{ dailyForecast.day.rainProbability }} %</h3>
                  </li>
                  <li v-if="dailyForecast.day.rainProbability > 0">
                    <h4>مقدار بارش باران : </h4>
                    <h3>{{ dailyForecast.day.rain.value }} {{ dailyForecast.day.rain.unit }}</h3>
                  </li>
                  <li v-if="dailyForecast.day.rainProbability > 0">
                    <h4>تعداد ساعات بارش باران : </h4>
                    <h3>{{ dailyForecast.day.hoursOfRain }}</h3>
                  </li>
                  <li v-if="dailyForecast.day.snowProbability > 0">
                    <h4>احتمال بارش برف : </h4>
                    <h3>{{ dailyForecast.day.snowProbability }} %</h3>
                  </li>
                  <li v-if="dailyForecast.day.snowProbability > 0">
                    <h4> مقدار بارش برف : </h4>
                    <h3>{{ dailyForecast.day.snow.value }} {{ dailyForecast.day.snow.unit }}</h3>
                  </li>
                  <li v-if="dailyForecast.day.snowProbability > 0">
                    <h4>تعداد ساعات بارش برف : </h4>
                    <h3>{{ dailyForecast.day.hoursOfSnow }}</h3>
                  </li>
                  <li v-if="dailyForecast.day.iceProbability > 0">
                    <h4> احتمال بارش تگرگ : </h4>
                    <h3>{{ dailyForecast.day.iceProbability }} %</h3>
                  </li>
                  <li v-if="dailyForecast.day.iceProbability > 0">
                    <h4>مقدار بارش تگرگ : </h4>
                    <h3>{{ dailyForecast.day.ice.value }} {{ dailyForecast.day.ice.unit }}</h3>
                  </li>
                  <li v-if="dailyForecast.day.iceProbability > 0">
                    <h4>تعداد ساعات بارش تگرگ : </h4>
                    <h3>{{ dailyForecast.day.hoursOfIce }}</h3>
                  </li>
                </ul>
              </div>
            </div>
            <div class="tonight">
              <span> <svg width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M19.9001 2.30719C19.7392 1.8976 19.1616 1.8976 19.0007 2.30719L18.5703 3.40247C18.5212 3.52752 18.4226 3.62651 18.298 3.67583L17.2067 4.1078C16.7986 4.26934 16.7986 4.849 17.2067 5.01054L18.298 5.44252C18.4226 5.49184 18.5212 5.59082 18.5703 5.71587L19.0007 6.81115C19.1616 7.22074 19.7392 7.22074 19.9001 6.81116L20.3305 5.71587C20.3796 5.59082 20.4782 5.49184 20.6028 5.44252L21.6941 5.01054C22.1022 4.849 22.1022 4.26934 21.6941 4.1078L20.6028 3.67583C20.4782 3.62651 20.3796 3.52752 20.3305 3.40247L19.9001 2.30719Z"
                    fill="#1C274C" />
                  <path
                    d="M16.0328 8.12967C15.8718 7.72009 15.2943 7.72009 15.1333 8.12967L14.9764 8.52902C14.9273 8.65407 14.8287 8.75305 14.7041 8.80237L14.3062 8.95987C13.8981 9.12141 13.8981 9.70107 14.3062 9.86261L14.7041 10.0201C14.8287 10.0694 14.9273 10.1684 14.9764 10.2935L15.1333 10.6928C15.2943 11.1024 15.8718 11.1024 16.0328 10.6928L16.1897 10.2935C16.2388 10.1684 16.3374 10.0694 16.462 10.0201L16.8599 9.86261C17.268 9.70107 17.268 9.12141 16.8599 8.95987L16.462 8.80237C16.3374 8.75305 16.2388 8.65407 16.1897 8.52902L16.0328 8.12967Z"
                    fill="#1C274C" />
                  <path
                    d="M12 22C17.5228 22 22 17.5228 22 12C22 11.5373 21.3065 11.4608 21.0672 11.8568C19.9289 13.7406 17.8615 15 15.5 15C11.9101 15 9 12.0899 9 8.5C9 6.13845 10.2594 4.07105 12.1432 2.93276C12.5392 2.69347 12.4627 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                    fill="#1C274C" />
                </svg></span>
              <div class="daily-detail-box">
                <div class="daily-detail-box-header">
                  <img :src="weatherIcon(dailyForecast.night.icon)" alt="" />
                  <h4>{{ dailyForecast.night.iconPhrase }} <small>{{ dailyForecast.night.longPhrase }}</small></h4>

                </div>
                <ul>
                  <li v-if="dailyForecast.night.precipitationProbability > 0">
                    <h4>احتمال بارش : </h4>
                    <h3>{{ dailyForecast.night.precipitationProbability }} %</h3>
                  </li>
                  <li v-if="dailyForecast.night.precipitationProbability > 0">
                    <h4>شدت بارش : </h4>
                    <h3>{{ dailyForecast.night.precipitationIntensity }}</h3>
                  </li>
                  <li v-if="dailyForecast.night.precipitationProbability > 0">
                    <h4>تعداد ساعات بارش :</h4>
                    <h3>{{ dailyForecast.night.hoursOfPrecipitation }}</h3>
                  </li>
                  <li v-if="dailyForecast.night.thunderstormProbability > 0">
                    <h4>احتمال طوفان رعد و برق</h4>
                    <h3>{{ dailyForecast.night.thunderstormProbability }}</h3>
                  </li>
                  <li>
                    <h4>سرعت باد : </h4>
                    <h3>{{ dailyForecast.night.wind.speed.value }} {{ dailyForecast.night.wind.speed.unit }}</h3>
                  </li>
                  <li>
                    <h4>جهت باد : </h4>
                    <h3>{{ dailyForecast.night.wind.direction.degrees }}</h3>
                  </li>
                  <li>
                    <h4>درصد ابرناکی : </h4>
                    <h3>{{ dailyForecast.night.cloudCover }} %</h3>
                  </li>
                  <li>
                    <h4>میانگین رطوبت نسبی : </h4>
                    <h3>{{ dailyForecast.night.relativeHumidity.average }} %</h3>
                  </li>
                  <li>
                    <h4>حداقل رطوبت نسبی : </h4>
                    <h3>{{ dailyForecast.night.relativeHumidity.minimum }} %</h3>
                  </li>
                  <li>
                    <h4> حداکثر رطوبت نسبی : </h4>
                    <h3>{{ dailyForecast.night.relativeHumidity.maximum }} %</h3>
                  </li>
                  <li>
                    <h4>شدت تابش خورشید : </h4>
                    <h3>{{ dailyForecast.night.solarIrradiance.value }} {{ dailyForecast.night.solarIrradiance.unit }}
                    </h3>
                  </li>
                  <li>
                    <h4>تبخیر و تعرق : </h4>
                    <h3>{{ dailyForecast.night.evapotranspiration.value }} {{
                      dailyForecast.night.evapotranspiration.unit }}
                    </h3>
                  </li>
                  <li>
                    <h4>سرعت تند باد ناگهانی : </h4>
                    <h3>{{ dailyForecast.night.windGust.speed.value }} {{ dailyForecast.night.windGust.speed.unit }}
                    </h3>
                  </li>
                  <li v-if="dailyForecast.night.rainProbability > 0">
                    <h4>احتمال بارش باران : </h4>
                    <h3>{{ dailyForecast.night.rainProbability }} %</h3>
                  </li>
                  <li v-if="dailyForecast.night.rainProbability > 0">
                    <h4>مقدار بارش باران : </h4>
                    <h3>{{ dailyForecast.night.rain.value }} {{ dailyForecast.night.rain.unit }}</h3>
                  </li>
                  <li v-if="dailyForecast.night.rainProbability > 0">
                    <h4>تعداد ساعات بارش باران : </h4>
                    <h3>{{ dailyForecast.night.hoursOfRain }}</h3>
                  </li>
                  <li v-if="dailyForecast.night.snowProbability > 0">
                    <h4>احتمال بارش برف : </h4>
                    <h3>{{ dailyForecast.night.snowProbability }} %</h3>
                  </li>
                  <li v-if="dailyForecast.night.snowProbability > 0">
                    <h4> مقدار بارش برف : </h4>
                    <h3>{{ dailyForecast.night.snow.value }} {{ dailyForecast.night.snow.unit }}</h3>
                  </li>
                  <li v-if="dailyForecast.night.snowProbability > 0">
                    <h4>تعداد ساعات بارش برف : </h4>
                    <h3>{{ dailyForecast.night.hoursOfSnow }}</h3>
                  </li>
                  <li v-if="dailyForecast.night.iceProbability > 0">
                    <h4> احتمال بارش تگرگ : </h4>
                    <h3>{{ dailyForecast.night.iceProbability }} %</h3>
                  </li>
                  <li v-if="dailyForecast.night.iceProbability > 0">
                    <h4>مقدار بارش تگرگ : </h4>
                    <h3>{{ dailyForecast.night.ice.value }} {{ dailyForecast.night.ice.unit }}</h3>
                  </li>
                  <li v-if="dailyForecast.night.iceProbability > 0">
                    <h4>تعداد ساعات بارش تگرگ : </h4>
                    <h3>{{ dailyForecast.night.hoursOfIce }}</h3>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="weather-info-box" v-show="courrentDetailForm == 'daily'">
        <h4 class="weather-info-box-header">پیش بینی پنج روز آینده</h4>
        <div class="daily-box">
          <div class="daily-info">
            <ul class="daily-info-body">
              <li>
                <h5>کمترین دما</h5>
                <i>{{ dailyForecast.temperature.minimum.value }} {{ dailyForecast.temperature.minimum.unit }}</i>
              </li>
              <li>
                <h5>بیشترین دما</h5>
                <i>{{ dailyForecast.temperature.maximum.value }} {{ dailyForecast.temperature.maximum.unit }}</i>
              </li>
              <li>
                <h5>حداقل دمایی که احساس می شود</h5>
                <i>{{ dailyForecast.realFeelTemperature.minimum.value }} {{
                  dailyForecast.realFeelTemperature.minimum.unit }}</i>
              </li>
              <li>
                <h5>حداکثر دمایی که احساس می شود</h5>
                <i>{{ dailyForecast.realFeelTemperature.maximum.value }} {{
                  dailyForecast.realFeelTemperature.maximum.unit }}</i>
              </li>
              <li>
                <h5>حداقل دمایی که در سایه احساس می شود</h5>
                <i>{{ dailyForecast.realFeelTemperatureShade.minimum.value }} {{
                  dailyForecast.realFeelTemperatureShade.minimum.unit }}</i>
              </li>
              <li>
                <h5>حداکثر دمایی که در سایه احساس می شود</h5>
                <i>{{ dailyForecast.realFeelTemperatureShade.maximum.value }} {{
                  dailyForecast.realFeelTemperatureShade.maximum.unit }}</i>
              </li>
              <li style="border:none">
                <h5>تعداد ساعات آفتابی</h5>
                <i>{{ dailyForecast.hoursOfSun }}</i>
              </li>
              <li>
                <h5>شاخص فرابنفش</h5>
                <i>{{ dailyForecast.airAndPollen[5].category }} {{ dailyForecast.airAndPollen[5].value }}</i>
              </li>
            </ul>
          </div>
          <div class="daily-detail">
            <div class="today">
              <span> <svg width="30px" height="30px" viewBox="0 0 24 24" fill="white"
                  xmlns="http://www.w3.org/2000/svg">
                  <rect width="24" height="24" fill="transparent" />
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12Z"
                    fill="#323232" />
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M12 2C12.5523 2 13 2.44772 13 3V5C13 5.55228 12.5523 6 12 6C11.4477 6 11 5.55228 11 5V3C11 2.44772 11.4477 2 12 2Z"
                    fill="#323232" />
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M19.7071 4.29289C20.0976 4.68342 20.0976 5.31658 19.7071 5.70711L17.7071 7.70711C17.3166 8.09763 16.6834 8.09763 16.2929 7.70711C15.9024 7.31658 15.9024 6.68342 16.2929 6.29289L18.2929 4.29289C18.6834 3.90237 19.3166 3.90237 19.7071 4.29289Z"
                    fill="#323232" />
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M18 12C18 11.4477 18.4477 11 19 11H21C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13H19C18.4477 13 18 12.5523 18 12Z"
                    fill="#323232" />
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M16.2929 16.2929C16.6834 15.9024 17.3166 15.9024 17.7071 16.2929L19.7071 18.2929C20.0976 18.6834 20.0976 19.3166 19.7071 19.7071C19.3166 20.0976 18.6834 20.0976 18.2929 19.7071L16.2929 17.7071C15.9024 17.3166 15.9024 16.6834 16.2929 16.2929Z"
                    fill="#323232" />
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M12 18C12.5523 18 13 18.4477 13 19V21C13 21.5523 12.5523 22 12 22C11.4477 22 11 21.5523 11 21V19C11 18.4477 11.4477 18 12 18Z"
                    fill="#323232" />
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M7.70711 16.2929C8.09763 16.6834 8.09763 17.3166 7.70711 17.7071L5.70711 19.7071C5.31658 20.0976 4.68342 20.0976 4.29289 19.7071C3.90237 19.3166 3.90237 18.6834 4.29289 18.2929L6.29289 16.2929C6.68342 15.9024 7.31658 15.9024 7.70711 16.2929Z"
                    fill="#323232" />
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M2 12C2 11.4477 2.44772 11 3 11H5C5.55228 11 6 11.4477 6 12C6 12.5523 5.55228 13 5 13H3C2.44772 13 2 12.5523 2 12Z"
                    fill="#323232" />
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M4.29289 4.29289C4.68342 3.90237 5.31658 3.90237 5.70711 4.29289L7.70711 6.29289C8.09763 6.68342 8.09763 7.31658 7.70711 7.70711C7.31658 8.09763 6.68342 8.09763 6.29289 7.70711L4.29289 5.70711C3.90237 5.31658 3.90237 4.68342 4.29289 4.29289Z"
                    fill="#323232" />
                </svg></span>
              <div class="daily-detail-box">
                <div class="daily-detail-box-header">
                  <img :src="weatherIcon(dailyForecast.day.icon)" alt="" />
                  <h4>{{ dailyForecast.day.iconPhrase }} <small>{{ dailyForecast.day.longPhrase }}</small></h4>

                </div>
                <ul>
                  <li v-if="dailyForecast.day.precipitationProbability > 0">
                    <h4>احتمال بارش : </h4>
                    <h3>{{ dailyForecast.day.precipitationProbability }} %</h3>
                  </li>
                  <li v-if="dailyForecast.day.precipitationProbability > 0">
                    <h4>شدت بارش : </h4>
                    <h3>{{ dailyForecast.day.precipitationIntensity }}</h3>
                  </li>
                  <li v-if="dailyForecast.day.precipitationProbability > 0">
                    <h4>تعداد ساعات بارش :</h4>
                    <h3>{{ dailyForecast.day.hoursOfPrecipitation }}</h3>
                  </li>
                  <li v-if="dailyForecast.day.thunderstormProbability > 0">
                    <h4>حتمال طوفان رعد و برق</h4>
                    <h3>{{ dailyForecast.day.thunderstormProbability }}</h3>
                  </li>
                  <li>
                    <h4>سرعت باد : </h4>
                    <h3>{{ dailyForecast.day.wind.speed.value }} {{ dailyForecast.day.wind.speed.unit }}</h3>
                  </li>
                  <li>
                    <h4>جهت باد : </h4>
                    <h3>{{ dailyForecast.day.wind.direction.degrees }}</h3>
                  </li>
                  <li>
                    <h4>درصد ابرناکی : </h4>
                    <h3>{{ dailyForecast.day.cloudCover }} %</h3>
                  </li>
                  <li>
                    <h4>میانگین رطوبت نسبی : </h4>
                    <h3>{{ dailyForecast.day.relativeHumidity.average }} %</h3>
                  </li>
                  <li>
                    <h4>حداقل رطوبت نسبی : </h4>
                    <h3>{{ dailyForecast.day.relativeHumidity.minimum }} %</h3>
                  </li>
                  <li>
                    <h4> حداکثر رطوبت نسبی : </h4>
                    <h3>{{ dailyForecast.day.relativeHumidity.maximum }} %</h3>
                  </li>
                  <li>
                    <h4>شدت تابش خورشید : </h4>
                    <h3>{{ dailyForecast.day.solarIrradiance.value }} {{ dailyForecast.day.solarIrradiance.unit }}</h3>
                  </li>
                  <li>
                    <h4>تبخیر و تعرق : </h4>
                    <h3>{{ dailyForecast.day.evapotranspiration.value }} {{ dailyForecast.day.evapotranspiration.unit }}
                    </h3>
                  </li>
                  <li>
                    <h4>سرعت تند باد ناگهانی : </h4>
                    <h3>{{ dailyForecast.day.windGust.speed.value }} {{ dailyForecast.day.windGust.speed.unit }}</h3>
                  </li>
                  <li v-if="dailyForecast.day.rainProbability > 0">
                    <h4>احتمال بارش باران : </h4>
                    <h3>{{ dailyForecast.day.rainProbability }} %</h3>
                  </li>
                  <li v-if="dailyForecast.day.rainProbability > 0">
                    <h4>مقدار بارش باران : </h4>
                    <h3>{{ dailyForecast.day.rain.value }} {{ dailyForecast.day.rain.unit }}</h3>
                  </li>
                  <li v-if="dailyForecast.day.rainProbability > 0">
                    <h4>تعداد ساعات بارش باران : </h4>
                    <h3>{{ dailyForecast.day.hoursOfRain }}</h3>
                  </li>
                  <li v-if="dailyForecast.day.snowProbability > 0">
                    <h4>احتمال بارش برف : </h4>
                    <h3>{{ dailyForecast.day.snowProbability }} %</h3>
                  </li>
                  <li v-if="dailyForecast.day.snowProbability > 0">
                    <h4> مقدار بارش برف : </h4>
                    <h3>{{ dailyForecast.day.snow.value }} {{ dailyForecast.day.snow.unit }}</h3>
                  </li>
                  <li v-if="dailyForecast.day.snowProbability > 0">
                    <h4>تعداد ساعات بارش برف : </h4>
                    <h3>{{ dailyForecast.day.hoursOfSnow }}</h3>
                  </li>
                  <li v-if="dailyForecast.day.iceProbability > 0">
                    <h4> احتمال بارش تگرگ : </h4>
                    <h3>{{ dailyForecast.day.iceProbability }} %</h3>
                  </li>
                  <li v-if="dailyForecast.day.iceProbability > 0">
                    <h4>مقدار بارش تگرگ : </h4>
                    <h3>{{ dailyForecast.day.ice.value }} {{ dailyForecast.day.ice.unit }}</h3>
                  </li>
                  <li v-if="dailyForecast.day.iceProbability > 0">
                    <h4>تعداد ساعات بارش تگرگ : </h4>
                    <h3>{{ dailyForecast.day.hoursOfIce }}</h3>
                  </li>
                </ul>
              </div>
            </div>
            <div class="tonight">
              <span> <svg width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M19.9001 2.30719C19.7392 1.8976 19.1616 1.8976 19.0007 2.30719L18.5703 3.40247C18.5212 3.52752 18.4226 3.62651 18.298 3.67583L17.2067 4.1078C16.7986 4.26934 16.7986 4.849 17.2067 5.01054L18.298 5.44252C18.4226 5.49184 18.5212 5.59082 18.5703 5.71587L19.0007 6.81115C19.1616 7.22074 19.7392 7.22074 19.9001 6.81116L20.3305 5.71587C20.3796 5.59082 20.4782 5.49184 20.6028 5.44252L21.6941 5.01054C22.1022 4.849 22.1022 4.26934 21.6941 4.1078L20.6028 3.67583C20.4782 3.62651 20.3796 3.52752 20.3305 3.40247L19.9001 2.30719Z"
                    fill="#1C274C" />
                  <path
                    d="M16.0328 8.12967C15.8718 7.72009 15.2943 7.72009 15.1333 8.12967L14.9764 8.52902C14.9273 8.65407 14.8287 8.75305 14.7041 8.80237L14.3062 8.95987C13.8981 9.12141 13.8981 9.70107 14.3062 9.86261L14.7041 10.0201C14.8287 10.0694 14.9273 10.1684 14.9764 10.2935L15.1333 10.6928C15.2943 11.1024 15.8718 11.1024 16.0328 10.6928L16.1897 10.2935C16.2388 10.1684 16.3374 10.0694 16.462 10.0201L16.8599 9.86261C17.268 9.70107 17.268 9.12141 16.8599 8.95987L16.462 8.80237C16.3374 8.75305 16.2388 8.65407 16.1897 8.52902L16.0328 8.12967Z"
                    fill="#1C274C" />
                  <path
                    d="M12 22C17.5228 22 22 17.5228 22 12C22 11.5373 21.3065 11.4608 21.0672 11.8568C19.9289 13.7406 17.8615 15 15.5 15C11.9101 15 9 12.0899 9 8.5C9 6.13845 10.2594 4.07105 12.1432 2.93276C12.5392 2.69347 12.4627 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                    fill="#1C274C" />
                </svg></span>
              <div class="daily-detail-box">
                <div class="daily-detail-box-header">
                  <img :src="weatherIcon(dailyForecast.night.icon)" alt="" />
                  <h4>{{ dailyForecast.night.iconPhrase }} <small>{{ dailyForecast.night.longPhrase }}</small></h4>

                </div>
                <ul>
                  <li v-if="dailyForecast.night.precipitationProbability > 0">
                    <h4>احتمال بارش : </h4>
                    <h3>{{ dailyForecast.night.precipitationProbability }} %</h3>
                  </li>
                  <li v-if="dailyForecast.night.precipitationProbability > 0">
                    <h4>شدت بارش : </h4>
                    <h3>{{ dailyForecast.night.precipitationIntensity }}</h3>
                  </li>
                  <li v-if="dailyForecast.night.precipitationProbability > 0">
                    <h4>تعداد ساعات بارش :</h4>
                    <h3>{{ dailyForecast.night.hoursOfPrecipitation }}</h3>
                  </li>
                  <li v-if="dailyForecast.night.thunderstormProbability > 0">
                    <h4>احتمال طوفان رعد و برق</h4>
                    <h3>{{ dailyForecast.night.thunderstormProbability }}</h3>
                  </li>
                  <li>
                    <h4>سرعت باد : </h4>
                    <h3>{{ dailyForecast.night.wind.speed.value }} {{ dailyForecast.night.wind.speed.unit }}</h3>
                  </li>
                  <li>
                    <h4>جهت باد : </h4>
                    <h3>{{ dailyForecast.night.wind.direction.degrees }}</h3>
                  </li>
                  <li>
                    <h4>درصد ابرناکی : </h4>
                    <h3>{{ dailyForecast.night.cloudCover }} %</h3>
                  </li>
                  <li>
                    <h4>میانگین رطوبت نسبی : </h4>
                    <h3>{{ dailyForecast.night.relativeHumidity.average }} %</h3>
                  </li>
                  <li>
                    <h4>حداقل رطوبت نسبی : </h4>
                    <h3>{{ dailyForecast.night.relativeHumidity.minimum }} %</h3>
                  </li>
                  <li>
                    <h4> حداکثر رطوبت نسبی : </h4>
                    <h3>{{ dailyForecast.night.relativeHumidity.maximum }} %</h3>
                  </li>
                  <li>
                    <h4>شدت تابش خورشید : </h4>
                    <h3>{{ dailyForecast.night.solarIrradiance.value }} {{ dailyForecast.night.solarIrradiance.unit }}
                    </h3>
                  </li>
                  <li>
                    <h4>تبخیر و تعرق : </h4>
                    <h3>{{ dailyForecast.night.evapotranspiration.value }} {{
                      dailyForecast.night.evapotranspiration.unit }}
                    </h3>
                  </li>
                  <li>
                    <h4>سرعت تند باد ناگهانی : </h4>
                    <h3>{{ dailyForecast.night.windGust.speed.value }} {{ dailyForecast.night.windGust.speed.unit }}
                    </h3>
                  </li>
                  <li v-if="dailyForecast.night.rainProbability > 0">
                    <h4>احتمال بارش باران : </h4>
                    <h3>{{ dailyForecast.night.rainProbability }} %</h3>
                  </li>
                  <li v-if="dailyForecast.night.rainProbability > 0">
                    <h4>مقدار بارش باران : </h4>
                    <h3>{{ dailyForecast.night.rain.value }} {{ dailyForecast.night.rain.unit }}</h3>
                  </li>
                  <li v-if="dailyForecast.night.rainProbability > 0">
                    <h4>تعداد ساعات بارش باران : </h4>
                    <h3>{{ dailyForecast.night.hoursOfRain }}</h3>
                  </li>
                  <li v-if="dailyForecast.night.snowProbability > 0">
                    <h4>احتمال بارش برف : </h4>
                    <h3>{{ dailyForecast.night.snowProbability }} %</h3>
                  </li>
                  <li v-if="dailyForecast.night.snowProbability > 0">
                    <h4> مقدار بارش برف : </h4>
                    <h3>{{ dailyForecast.night.snow.value }} {{ dailyForecast.night.snow.unit }}</h3>
                  </li>
                  <li v-if="dailyForecast.night.snowProbability > 0">
                    <h4>تعداد ساعات بارش برف : </h4>
                    <h3>{{ dailyForecast.night.hoursOfSnow }}</h3>
                  </li>
                  <li v-if="dailyForecast.night.iceProbability > 0">
                    <h4> احتمال بارش تگرگ : </h4>
                    <h3>{{ dailyForecast.night.iceProbability }} %</h3>
                  </li>
                  <li v-if="dailyForecast.night.iceProbability > 0">
                    <h4>مقدار بارش تگرگ : </h4>
                    <h3>{{ dailyForecast.night.ice.value }} {{ dailyForecast.night.ice.unit }}</h3>
                  </li>
                  <li v-if="dailyForecast.night.iceProbability > 0">
                    <h4>تعداد ساعات بارش تگرگ : </h4>
                    <h3>{{ dailyForecast.night.hoursOfIce }}</h3>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="huorly-forecasts-date">
          <ul>
            <li style="padding:0px 10px;" @click="activeDailyForcast(1)"
              :class="dailyForcastActiveIndex == 1 ? 'active' : ''">
              <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M3 5.5L5 3.5M21 5.5L19 3.5M12 8.5V13.5M12 16.5H12.01M20 12.5C20 16.9183 16.4183 20.5 12 20.5C7.58172 20.5 4 16.9183 4 12.5C4 8.08172 7.58172 4.5 12 4.5C16.4183 4.5 20 8.08172 20 12.5Z"
                  stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <h4>{{ getPersion(dailyForecastList.dailyForecasts[1]?.date, "YYYY/MM/DD") }}</h4>
            </li>
            <li style="padding:0px 10px;" @click="activeDailyForcast(2)"
              :class="dailyForcastActiveIndex == 2 ? 'active' : ''">
              <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M3 5.5L5 3.5M21 5.5L19 3.5M12 8.5V13.5M12 16.5H12.01M20 12.5C20 16.9183 16.4183 20.5 12 20.5C7.58172 20.5 4 16.9183 4 12.5C4 8.08172 7.58172 4.5 12 4.5C16.4183 4.5 20 8.08172 20 12.5Z"
                  stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <h4>{{ getPersion(dailyForecastList.dailyForecasts[2]?.date, "YYYY/MM/DD") }}</h4>
            </li>
            <li style="padding:0px 10px;" @click="activeDailyForcast(3)"
              :class="dailyForcastActiveIndex == 3 ? 'active' : ''">
              <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M3 5.5L5 3.5M21 5.5L19 3.5M12 8.5V13.5M12 16.5H12.01M20 12.5C20 16.9183 16.4183 20.5 12 20.5C7.58172 20.5 4 16.9183 4 12.5C4 8.08172 7.58172 4.5 12 4.5C16.4183 4.5 20 8.08172 20 12.5Z"
                  stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <h4>{{ getPersion(dailyForecastList.dailyForecasts[3]?.date, "YYYY/MM/DD") }}</h4>
            </li>
            <li style="padding:0px 10px;" @click="activeDailyForcast(4)"
              :class="dailyForcastActiveIndex == 4 ? 'active' : ''">
              <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M3 5.5L5 3.5M21 5.5L19 3.5M12 8.5V13.5M12 16.5H12.01M20 12.5C20 16.9183 16.4183 20.5 12 20.5C7.58172 20.5 4 16.9183 4 12.5C4 8.08172 7.58172 4.5 12 4.5C16.4183 4.5 20 8.08172 20 12.5Z"
                  stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <h4>{{ getPersion(dailyForecastList.dailyForecasts[4]?.date, "YYYY/MM/DD") }}</h4>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="weather-info-btn">
      <button @click="showDetail('windchart')" :class="courrentDetailForm == 'windchart' ? 'active' : ''"
        style="display: flex;justify-content: center;align-items: center;">
        <svg height="35px" width="35px" version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" xml:space="preserve">
          <g>
            <path class="st0" d="M255.988,181.426c7.456,0,14.499,1.71,20.747,4.765V17.334c0-9.579-5.3-17.334-14.142-17.334

c-12.829,0-20.073,12.853-23.266,30.544c-2.439,13.364-20.082,91.755-20.082,118.06c0,13.429,3.971,29.078,8.493,42.133

C235.648,184.887,245.421,181.426,255.988,181.426z" />
            <path class="st0" d="M240.477,273.78c-16.58-5.722-28.989-20.35-31.492-38.162c-48.43,27.968-140.25,80.984-143.548,82.865

c-8.29,4.789-12.342,13.25-7.934,20.925c6.426,11.118,21.168,10.948,38.073,4.894c12.804-4.587,89.518-28.478,112.298-41.646

C219.666,295.856,231.417,284.348,240.477,273.78z" />
            <path class="st0" d="M439.19,311.902c-10.373-8.801-69.436-63.277-92.216-76.422c-12.61-7.294-29.815-11.831-44.127-14.319

c0.422,2.504,0.641,5.097,0.641,7.739c0,15.179-7.115,28.705-18.194,37.344c47.498,27.416,143.24,82.711,146.612,84.663

c8.282,4.79,17.634,4.076,22.075-3.59C460.398,336.19,452.886,323.515,439.19,311.902z" />
            <path class="st0" d="M221.06,228.908c0,19.296,15.641,34.937,34.928,34.937c19.304,0,34.946-15.641,34.946-34.937

c0-19.295-15.641-34.928-34.946-34.928C236.701,193.98,221.06,209.612,221.06,228.908z M272.861,228.908

c0,9.32-7.552,16.865-16.873,16.865c-9.304,0-16.856-7.545-16.856-16.865c0-9.311,7.552-16.864,16.856-16.864

C265.309,212.043,272.861,219.596,272.861,228.908z" />
            <polygon class="st0" points="284.013,293.376 232.827,293.376 220.639,470.507 203.45,470.507 203.45,512 217.786,512 299.743,512 

314.079,512 314.079,470.507 296.753,470.507 	" />
          </g>
        </svg>
      </button>
      <button @click="showDetail('historical')" :class="courrentDetailForm == 'historical' ? 'active' : ''">
        <h4>24</h4>
        <span>ساعت گذشته</span>
      </button>
      <button @click="showDetail('forecasts')" :class="courrentDetailForm == 'forecasts' ? 'active' : ''">
        <h4>12</h4>
        <span>ساعت آینده</span>
      </button>
      <button @click="showDetail('today')" :class="courrentDetailForm == 'today' ? 'active' : ''">
        <svg width="24px" height="24px" viewBox="0 0 24 24" fill="white" xmlns="http://www.w3.org/2000/svg">
          <rect width="24" height="24" fill="transparent" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12Z"
            fill="#323232" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M12 2C12.5523 2 13 2.44772 13 3V5C13 5.55228 12.5523 6 12 6C11.4477 6 11 5.55228 11 5V3C11 2.44772 11.4477 2 12 2Z"
            fill="#323232" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M19.7071 4.29289C20.0976 4.68342 20.0976 5.31658 19.7071 5.70711L17.7071 7.70711C17.3166 8.09763 16.6834 8.09763 16.2929 7.70711C15.9024 7.31658 15.9024 6.68342 16.2929 6.29289L18.2929 4.29289C18.6834 3.90237 19.3166 3.90237 19.7071 4.29289Z"
            fill="#323232" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M18 12C18 11.4477 18.4477 11 19 11H21C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13H19C18.4477 13 18 12.5523 18 12Z"
            fill="#323232" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M16.2929 16.2929C16.6834 15.9024 17.3166 15.9024 17.7071 16.2929L19.7071 18.2929C20.0976 18.6834 20.0976 19.3166 19.7071 19.7071C19.3166 20.0976 18.6834 20.0976 18.2929 19.7071L16.2929 17.7071C15.9024 17.3166 15.9024 16.6834 16.2929 16.2929Z"
            fill="#323232" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M12 18C12.5523 18 13 18.4477 13 19V21C13 21.5523 12.5523 22 12 22C11.4477 22 11 21.5523 11 21V19C11 18.4477 11.4477 18 12 18Z"
            fill="#323232" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M7.70711 16.2929C8.09763 16.6834 8.09763 17.3166 7.70711 17.7071L5.70711 19.7071C5.31658 20.0976 4.68342 20.0976 4.29289 19.7071C3.90237 19.3166 3.90237 18.6834 4.29289 18.2929L6.29289 16.2929C6.68342 15.9024 7.31658 15.9024 7.70711 16.2929Z"
            fill="#323232" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M2 12C2 11.4477 2.44772 11 3 11H5C5.55228 11 6 11.4477 6 12C6 12.5523 5.55228 13 5 13H3C2.44772 13 2 12.5523 2 12Z"
            fill="#323232" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M4.29289 4.29289C4.68342 3.90237 5.31658 3.90237 5.70711 4.29289L7.70711 6.29289C8.09763 6.68342 8.09763 7.31658 7.70711 7.70711C7.31658 8.09763 6.68342 8.09763 6.29289 7.70711L4.29289 5.70711C3.90237 5.31658 3.90237 4.68342 4.29289 4.29289Z"
            fill="#323232" />
        </svg>
        <span>امروز</span>
      </button>
      <button @click="showDetail('daily')" :class="courrentDetailForm == 'daily' ? 'active' : ''">
        <h4>4</h4>
        <span>روز آینده</span>
      </button>
    </div>

    <div class="farm-advice">
      <h4>یکشنبه 12 دی 1403</h4>
      <div class="advice-msg">
        <span>
          <svg width="40px" height="40px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 17V11" stroke="#ff1100" stroke-width="1.5" stroke-linecap="round" />
            <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 11 9)" fill="#ff1100" />
            <path
              d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7"
              stroke="#ff1100" stroke-width="1.5" stroke-linecap="round" />
          </svg></span>
        <small>امروز مناسب برای سم پاشی نیست</small>
      </div>
    </div>

  </div>
</template>

<script>
import * as moment from "jalali-moment";
import {
  mapActions
} from "vuex";
import {
  apiUrlRoot
} from "../../constants/config";
import Anychart from 'anychart';

export default {
  name: "WeatherMonitoring",
  data() {
    return {
      loading: false,
      loadingText: "",
      currentFarm: {
        id: 0,
        farmerId: 0,
        farmUserId: null,
        legalEntityId: null,
        title: null,
        farmerName: null,
        productTypeId: 2,
        productType: null,
        productId: 0,
        productName: null,
        productImage: null,
        cultivationsType: 0,
        cultivationsTypeTitle: null,
        cultivationsDate: null,
        cultivationsEndDate: null,
        countryId: 0,
        stateId: 0,
        stateName: null,
        cityId: 0,
        cityName: null,
        district: null,
        village: null,
        farmArea: 0,
        cordinates: [],
        coordination: null,
        area: null,
        advicerCount: 0,
        farmuserCount: 0,
        farmImage: null,
        kashtDatePersian: null,
      },
      currentWeather: {
        localObservationDateTime: "0001-01-01T00:00:00",
        epochTime: 0,
        weatherText: null,
        weatherIcon: 0,
        hasPrecipitation: false,
        precipitationType: null,
        isDayTime: false,
        temperature: {
          metric: {
            value: 0,
            unit: "",
            unitType: 0,
            phrase: null
          },
          imperial: {
            value: 0,
            unit: "",
            unitType: 0,
            phrase: null
          }
        },
        realFeelTemperature: {
          metric: {
            value: 0,
            unit: "",
            unitType: 0,
            phrase: null
          },
          imperial: {
            value: 0,
            unit: "",
            unitType: 0,
            phrase: null
          }
        },
        realFeelTemperatureShade: {
          metric: {
            value: 0,
            unit: "",
            unitType: 0,
            phrase: null
          },
          imperial: {
            value: 0,
            unit: "",
            unitType: 0,
            phrase: null
          }
        },
        relativeHumidity: 0,
        indoorRelativeHumidity: 0,
        dewPoint: {
          metric: {
            value: 0,
            unit: "",
            unitType: 0,
            phrase: null
          },
          imperial: {
            value: 0,
            unit: "",
            unitType: 0,
            phrase: null
          }
        },
        wind: {
          direction: {
            degrees: 0,
            localized: "",
            english: ""
          },
          speed: {
            metric: {
              value: 0,
              unit: "",
              unitType: 7,
              phrase: null
            },
            imperial: {
              value: 0,
              unit: "",
              unitType: 0,
              phrase: null
            }
          }
        },
        windGust: null,
        uvIndex: 0,
        uvIndexText: null,
        visibility: {
          metric: {
            value: 0,
            unit: "",
            unitType: 0,
            phrase: null
          },
          imperial: {
            value: 0,
            unit: "",
            unitType: 0,
            phrase: null
          }
        },
        obstructionsToVisibility: null,
        cloudCover: 0,
        ceiling: null,
        pressure: {
          metric: {
            value: 0,
            unit: "",
            unitType: 0,
            phrase: null
          },
          imperial: {
            value: 0,
            unit: "",
            unitType: 0,
            phrase: null
          }
        },
        pressureTendency: null,
        past24HourTemperatureDeparture: null,
        apparentTemperature: null,
        windChillTemperature: null,
        wetBulbTemperature: null,
        wetBulbGlobeTemperature: null,
        precip1hr: null,
        precipitationSummary: null,
        temperatureSummary: null,
      },
      hourlyHispory: [],
      hourlyForcastList: [],
      hourlyForcast: {
        dateTime: "",
        epochDateTime: 0,
        weatherIcon: 33,
        iconPhrase: "",
        hasPrecipitation: null,
        isDaylight: null,
        temperature: {
          value: 0,
          unit: "",
          unitType: 0
        },
        realFeelTemperature: {
          value: 0,
          unit: "",
          unitType: 0,
          phrase: ""
        },
        realFeelTemperatureShade: {
          value: 0,
          unit: "",
          unitType: 0,
          phrase: ""
        },
        wetBulbTemperature: {
          value: 0,
          unit: "",
          unitType: 0
        },
        wetBulbGlobeTemperature: {
          value: 0,
          unit: "",
          unitType: 0
        },
        dewPoint: {
          value: 0,
          unit: "",
          unitType: 0
        },
        wind: {
          speed: {
            value: 0,
            unit: "",
            unitType: 0
          },
          direction: {
            degrees: 0,
            localized: "",
            english: ""
          }
        },
        windGust: {
          speed: {
            value: 0,
            unit: "",
            unitType: 0
          }
        },
        relativeHumidity: 0,
        indoorRelativeHumidity: 0,
        visibility: {
          value: 0,
          unit: "",
          unitType: 0
        },
        ceiling: {
          value: 0,
          unit: "",
          unitType: 0
        },
        uvIndex: 0,
        uvIndexText: "",
        precipitationProbability: 0,
        thunderstormProbability: 0,
        rainProbability: 0,
        snowProbability: 0,
        iceProbability: 0,
        totalLiquid: {
          value: 0,
          unit: "",
          unitType: 0
        },
        rain: {
          value: 0,
          unit: "",
          unitType: 0
        },
        snow: {
          value: 0,
          unit: "",
          unitType: 0
        },
        ice: {
          value: 0,
          unit: "",
          unitType: 0
        },
        cloudCover: 0,
        evapotranspiration: {
          value: 0,
          unit: "",
          unitType: 0
        },
        solarIrradiance: {
          value: 0,
          unit: "",
          unitType: 0
        },
      },
      dailyForecastList: {
        headline: {
          effectiveDate: "",
          effectiveEpochDate: 0,
          severity: null,
          text: "",
          category: "",
          endDate: "",
          endEpochDate: 0,
        },
        dailyForecasts: []
      },
      dailyForecast: {
        date: "",
        epochDate: 0,
        sun: {
          rise: "",
          epochRise: 0,
          set: "",
          epochSet: 0
        },
        moon: {
          rise: "",
          epochRise: 0,
          set: "",
          epochSet: 0,
          phase: "",
          age: 0
        },
        temperature: {
          minimum: {
            value: 0,
            unit: "",
            unitType: 0,
            phrase: null
          },
          maximum: {
            value: 0,
            unit: "",
            unitType: 0,
            phrase: null
          }
        },
        realFeelTemperature: {
          minimum: {
            value: 0,
            unit: "",
            unitType: 0,
            phrase: null
          },
          maximum: {
            value: 0,
            unit: "",
            unitType: 0,
            phrase: null
          }
        },
        realFeelTemperatureShade: {
          minimum: {
            value: 0,
            unit: "",
            unitType: 0,
            phrase: null
          },
          maximum: {
            value: 0,
            unit: "",
            unitType: 0,
            phrase: null
          }
        },
        hoursOfSun: 0,
        degreeDaySummary: {
          heating: {
            value: 0,
            unit: "",
            unitType: 0,
          },
          cooling: {
            value: 0,
            unit: "",
            unitType: 0,
          }
        },
        airAndPollen: [
          {
            name: "",
            value: 0,
            category: "",
            categoryValue: 0,
            type: ""
          },
          {
            name: "",
            value: 0,
            category: "",
            categoryValue: 0,
            type: ""
          },
          {
            name: "",
            value: 0,
            category: "",
            categoryValue: 0,
            type: ""
          },
          {
            name: "",
            value: 0,
            category: "",
            categoryValue: 0,
            type: ""
          },
          {
            name: "",
            value: 0,
            category: "",
            categoryValue: 0,
            type: ""
          },
          {
            name: "",
            value: 0,
            category: "",
            categoryValue: 0,
            type: ""
          }
        ],
        day: {
          icon: 0,
          iconPhrase: "",
          hasPrecipitation: false,
          shortPhrase: "",
          longPhrase: "",
          precipitationProbability: 0,
          thunderstormProbability: 0,
          rainProbability: 0,
          snowProbability: 0,
          iceProbability: 0,
          wind: {
            speed: {
              value: 0,
              unit: "",
              unitType: 0
            },
            direction: {
              degrees: 0,
              localized: "",
              english: ""
            }
          },
          windGust: {
            speed: {
              value: 0,
              unit: "",
              unitType: 0
            },
            direction: {
              degrees: 0,
              localized: "",
              english: ""
            }
          },
          totalLiquid: {
            value: 0,
            unit: "",
            unitType: 0
          },
          rain: {
            value: 0,
            unit: "",
            unitType: 0
          },
          snow: {
            value: 0,
            unit: "",
            unitType: 0
          },
          ice: {
            value: 0,
            unit: "",
            unitType: 0
          },
          hoursOfPrecipitation: 0,
          hoursOfRain: 0,
          hoursOfSnow: 0,
          hoursOfIce: 0,
          cloudCover: 0,
          evapotranspiration: {
            value: 0,
            unit: "",
            unitType: 0
          },
          solarIrradiance: {
            value: 0,
            unit: "",
            unitType: 0
          },
          relativeHumidity: {
            minimum: 0,
            maximum: 0,
            average: 0
          },
          wetBulbTemperature: {
            minimum: {
              value: 0,
              unit: "",
              unitType: 0,
              phrase: null
            },
            maximum: {
              value: 0,
              unit: "",
              unitType: 0,
              phrase: null
            },
            average: {
              value: 0,
              unit: "",
              unitType: 0,
            }
          },
          wetBulbGlobeTemperature: {
            minimum: {
              value: 0,
              unit: "",
              unitType: 0,
              phrase: null
            },
            maximum: {
              value: 0,
              unit: "",
              unitType: 0,
              phrase: null
            },
            average: {
              value: 0,
              unit: "",
              unitType: 0,
            }
          },
          precipitationType: null,
          precipitationIntensity: null
        },
        night: {
          icon: 0,
          iconPhrase: "",
          hasPrecipitation: false,
          shortPhrase: "",
          longPhrase: "",
          precipitationProbability: 0,
          thunderstormProbability: 0,
          rainProbability: 0,
          snowProbability: 0,
          iceProbability: 0,
          wind: {
            speed: {
              value: 0,
              unit: "",
              unitType: 0,
            },
            direction: {
              degrees: 0,
              localized: "",
              english: ""
            }
          },
          windGust: {
            speed: {
              value: 0,
              unit: "",
              unitType: 0,
            },
            direction: {
              degrees: 0,
              localized: "",
              english: ""
            }
          },
          totalLiquid: {
            value: 0,
            unit: "",
            unitType: 0,
          },
          rain: {
            value: 0,
            unit: "",
            unitType: 0,
          },
          snow: {
            value: 0,
            unit: "",
            unitType: 0,
          },
          ice: {
            value: 0,
            unit: "",
            unitType: 0,
          },
          hoursOfPrecipitation: 0,
          hoursOfRain: 0,
          hoursOfSnow: 0,
          hoursOfIce: 0,
          cloudCover: 0,
          evapotranspiration: {
            value: 0,
            unit: "",
            unitType: 0,
          },
          solarIrradiance: {
            value: 0,
            unit: "",
            unitType: 0,
          },
          relativeHumidity: {
            minimum: 0,
            maximum: 0,
            average: 0
          },
          wetBulbTemperature: {
            minimum: {
              value: 0,
              unit: "",
              unitType: 0,
              phrase: null
            },
            maximum: {
              value: 0,
              unit: "",
              unitType: 0,
              phrase: null
            },
            average: {
              value: 0,
              unit: "",
              unitType: 0,
            }
          },
          wetBulbGlobeTemperature: {
            minimum: {
              value: 0,
              unit: "",
              unitType: 0,
              phrase: null
            },
            maximum: {
              value: 0,
              unit: "",
              unitType: 0,
              phrase: null
            },
            average: {
              value: 0,
              unit: "",
              unitType: 0,
            }
          },
          precipitationType: null,
          precipitationIntensity: null
        },
      },


      weatherType: {
        corrent: "Current",
        daily: "DailyForecast",
        history: "History",
        hourly: "HourlyForcast"
      },
      hourlyForcastActiveIndex: 0,
      dailyForcastActiveIndex: 0,
      courrentDetailForm: "windchart",
    };
  },
  components: {},
  computed: {
    productImage() {
      return `${apiUrlRoot}ProductImage/${this.currentFarm.productImage}`;
    },
  },
  methods: {
    ...mapActions("weather", ["GetMessage", "GetWeather"]),

    async currrentWeather() {
      if (this.currentWeather.epochTime == 0) {
        this.loading = true;
        this.loadingText = "دریافت اطلاعات هواشناسی زمین";
        let result = await this.GetWeather({
          farmId: this.currentFarm.id,
          type: this.weatherType.corrent
        });
        if (result.succeeded) {
          this.currentWeather = result.data;
          window.degrees = this.currentWeather.wind.direction.degrees;
          window.unit = this.currentWeather.wind.speed.metric.unit;
          window.speed = this.currentWeather.wind.speed.metric.value;
          this.windChart();
        }
        else {
          this.$router.push({
            name: "صفحه اصلی"
          });
          this.$notify({
            title: "پیام سیستم",
            text: `خطا در سرور هواشناسی رخ داده است`,
            type: "error",
          });
        }
        this.loading = false;
      }
    },
    async DailyForecast() {
      if (this.dailyForecastList.dailyForecasts.length == 0) {
        this.loading = true;
        this.loadingText = "دریافت اطلاعات هواشناسی زمین";
        let result = await this.GetWeather({
          farmId: this.currentFarm.id,
          type: this.weatherType.daily
        });
        if (result.succeeded) {
          this.dailyForecastList = result.data;
        }
        else {
          this.$notify({
            title: "پیام سیستم",
            text: `خطا در سرور هواشناسی رخ داده است`,
            type: "error",
          });
        }
        this.loading = false;
      }
    },
    async ToDay() {
      if (this.dailyForecastList.dailyForecasts.length == 0)
        await this.DailyForecast();
      this.activeDailyForcast(0);
    },
    async History() {
      if (this.hourlyHispory.length == 0) {
        this.hourlyHispory = await this.GetWeather({
          farmId: this.currentFarm.id,
          type: this.weatherType.history
        });
        console.log("hourlyHispory", this.hourlyHispory);
      }
    },
    async HourlyForcast() {
      if (this.hourlyForcastList.length == 0) {
        this.loading = true;
        this.loadingText = "دریافت اطلاعات 12 ساعت آینده زمین";
        let result = await this.GetWeather({
          farmId: this.currentFarm.id,
          type: this.weatherType.hourly
        });
        if (result.succeeded) {
          this.hourlyForcastList = result.data;
          this.hourlyForcast = this.hourlyForcastList[this.hourlyForcastActiveIndex];
        }
        else {
          this.$notify({
            title: "پیام سیستم",
            text: `خطا در سرور هواشناسی رخ داده است`,
            type: "error",
          });
        }
        this.loading = false;
      }
    },
    windChart() {
      Anychart.onDocumentReady(function () {
        var gauge = Anychart.gauges.circular();
        gauge.background().fill("transparent");
        gauge
          .fill('#fff')
          .stroke(null)
          .padding(0)
          .margin(30)
          .startAngle(0)
          .sweepAngle(360);

        gauge
          .axis()
          .labels()
          .padding(1)
          .position('inside')
          .format('{%value}\u00B0');

        gauge.data([window.degrees, window.speed]);

        gauge
          .axis()
          .scale()
          .minimum(0)
          .maximum(360)
          .ticks({
            interval: 30
          })
          .minorTicks({
            interval: 10
          });

        gauge
          .axis()
          .fill('#7c868e')
          .startAngle(0)
          .sweepAngle(-360)
          .width(1)
          .ticks({
            type: 'line',
            fill: '#7c868e',
            length: 4,
            position: 'outside'
          });

        gauge
          .axis(1)
          .fill('#7c868e')
          .startAngle(270)
          .radius(40)
          .sweepAngle(180)
          .width(1)
          .ticks({
            type: 'line',
            fill: '#7c868e',
            length: 4,
            position: 'outside'
          });

        gauge
          .axis(1)
          .labels()
          .padding(3)
          .position('outside')
          .format('{%value}' + window.unit);

        gauge
          .axis(1)
          .scale()
          .minimum(0)
          .maximum(40)
          .ticks({
            interval: 10
          })
          .minorTicks({
            interval: 1
          });

        gauge.title().padding(0).margin([0, 0, 10, 0]);

        gauge
          .marker()
          .fill('#64b5f6')
          .stroke(null)
          .size('15%')
          .zIndex(120)
          .radius('97%');

        gauge
          .needle()
          .fill('#1976d2')
          .stroke(null)
          .axisIndex(1)
          .startRadius('6%')
          .endRadius('38%')
          .startWidth('2%')
          .middleWidth(null)
          .endWidth('0');

        gauge.cap().radius('4%').fill('#1976d2').enabled(true).stroke(null);

        var bigTooltipTitleSettings = {
          fontFamily: '\'Verdana\', Helvetica, Arial, sans-serif',
          fontWeight: 'normal',
          fontSize: '12px',
          hAlign: 'left',
          fontColor: '#f80',
        };

        gauge
          .label()
          .text(
            '<span style="color: #5AA3DD; font-size: 15px">' +
            '<span style="color: #f80; font-size: 13px">جهت باد: </span>' +
            window.degrees +
            '\u00B0 (+/- 0.5\u00B0)</span><br>' +
            '<span style="color: #f80; font-size: 15px">' +
            '<span style="color: #1976d2; font-size: 13px">' + window.unit + ' ' + window.speed + '</span> ' +
            ':سرعت باد</span>'
          )
          .useHtml(true)
          .textSettings(bigTooltipTitleSettings);
        gauge
          .label()
          .hAlign('center')
          .anchor('center-top')
          .offsetY(-20)
          .padding(15, 20)
          .background({
            fill: '#fff',
            stroke: {
              thickness: 1,
              color: '#E0F0FD'
            }
          });
        gauge.container('windchart');
        gauge.draw();
      });
    },
    async showDetail(activeForm) {
      this.courrentDetailForm = activeForm;
      if (activeForm == "historical") {
        await this.History();
      } else if (activeForm == "forecasts") {
        await this.HourlyForcast();
      } else if (activeForm == "today") {
        await this.ToDay();
      } else if (activeForm == "daily") {
        await this.DailyForecast();
        this.activeDailyForcast(1);
      } else {
        await this.currrentWeather();
      }
    },
    weatherIcon(name) {
      return `https://developer.accuweather.com/sites/default/files/${name.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      })}-s.png`;
    },
    getPersion(date, format) {
      if (date) {
        return moment(date)
          .locale("fa")
          .format(format);
      }
    },
    activeHourlyForcast(index) {
      this.hourlyForcastActiveIndex = index;
      this.hourlyForcast = this.hourlyForcastList[this.hourlyForcastActiveIndex];
    },
    activeDailyForcast(index) {
      this.dailyForcastActiveIndex = index;
      this.dailyForecast = this.dailyForecastList.dailyForecasts[this.dailyForcastActiveIndex];
    }
  },
  mounted() {
    if (this.$route.params.farm) {
      this.currentFarm = this.$route.params.farm;
      this.currentFarm.farmImage = `${apiUrlRoot}Farm/${this.currentFarm.farmImage}`;
      this.currrentWeather()
    } else {
      this.$router.push({
        name: "صفحه اصلی"
      });
      this.$notify({
        title: "پیام سیستم",
        text: `ابتدا زمین را انتخاب کنید`,
        type: "error",
      });
    }
  },
  created() { },
};
</script>

<style scoped>
.farm-current {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  z-index: 101;
  top: 4rem;
  right: 2rem;
  width: 30%;
  background-color: #000000cc;
  height: 85%;
  border-radius: 10px;
}

.farm-info {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 0 0 35%;
}

.farm-info>img {
  position: absolute;
  width: 100%;
  height: 100%;
}

.farm-info-more {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #455a64;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 5px;
}

.farm-info-more img {
  width: 3.5rem;
}

.farm-info-more ul {
  list-style: none;
  margin: 0;
  padding: 0;
  padding-right: 1rem;
  flex-grow: 1;
}

.farm-info-more ul li {
  color: #fff;
}

.farm-info-more ul li.title {
  font-size: 1rem;
  color: #ffa726;
  font-weight: bold;
  font-family: "Gandom";
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.weather-info-list {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.weather-info-list-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.weather-info-list-header img {
  width: 35%;
}

.weather-info-list-header ul {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.weather-info-list-header ul li {
  font-size: large;
  font-family: "Dirooz";
  font-weight: bold;
  color: #fff;
}

.weather-info-list-header ul li:last-child {
  font-size: 0.7rem;
  color: #fff;
  font-family: "ShabnamBold";
}

.weather-info-list-header ul li i {
  font-family: "Nazanin";
  font-size: 0.6rem;
  font-style: normal;
  margin-left: 1px;
}

.weather-info-list-header span {
  margin-right: auto;
  margin-left: 2rem;
  font-size: 2rem;
  color: #ffffff;
  font-weight: bold;
  font-family: "Dirooz";
  border: 1px solid #bbbbbb66;
  padding: 4px;
  border-radius: 7px;
  text-align: center;
}

.weather-info-list-header span i {
  color: #fff;
  font-style: normal;
  font-size: 1rem;
}

.weather-info-list-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 5px 1.4rem;
  border: 1px dashed #bbbbbb38;
  padding: 5px;
}

.weather-info-list-item img {
  width: 25%;
}

.weather-info-list-item ul {
  flex-grow: 1;
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.weather-info-list-item ul li {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px dotted #ffffff3b;
  margin: 0px 1rem;
  padding: 3px 0px;
}

.weather-info-list-item ul li:last-child {
  border-bottom: none;
}

.weather-info-list-item ul li h4 {
  color: #fff;
  font-size: 0.8rem;
  margin: 0px;
  padding: 0px;
}

.weather-info-list-item ul li h3 {
  color: #fff;
  font-size: 1rem;
  margin: 0px;
  padding: 0px;
}

.background_video {
  position: fixed;
  top: 0;
  right: 10vw;
  width: 100vw;
  z-index: 100;
  height: 100%;
}

.weather-info-btn {
  position: absolute;
  left: 7rem;
  bottom: 10rem;
  z-index: 101;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  gap: 10px;
  width: 40%;
}

.weather-info-btn button {
  border: none;
  display: flex;
  flex-direction: column;
  flex: 0 0 18%;
  gap: 8px;
  align-items: center;
  background-color: #00000099;
  border-radius: 30px;
  color: #ffffff;
  padding: 6px 0px;
  border: 2px solid #0000003b;
}

.weather-info-btn button span {
  font-family: "Dirooz";
}

.weather-info-btn button svg path {
  fill: #fff;
}

.weather-info-btn button.active {
  background-color: #ff57228a;
  border: 2px solid #FF5722;
}

.st0 {
  fill: #fff;
}

.farm-advice {
  position: absolute;
  left: 7rem;
  bottom: 5rem;
  z-index: 101;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  gap: 1px;
  min-width: 40%;
}

.farm-advice h4 {
  margin: 0px;
  color: #282828;
  font-weight: bold;
  font-size: 1rem;
}

.advice-msg {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
}

@keyframes state_action {
  0% {
    opacity: 0.1;
  }

  25% {
    opacity: 0.25;
  }

  50% {
    opacity: 0.5;
  }

  75% {
    opacity: 0.75;
  }

  100% {
    opacity: 1;
  }
}

.advice-msg span {
  animation-name: state_action;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-delay: 0s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-fill-mode: none;
  animation-play-state: running;
}

.advice-msg small {
  font-size: 1rem;
  color: red;
  font-weight: bold;
  font-family: 'Gandom';
}
</style>
<style scoped>
.weather-info {
  position: absolute;
  width: 39%;
  height: 59%;
  background-color: #0000009e;
  background: #000000ab;
  background-image: radial-gradient(#0000002b 1px, transparent 1.1px);
  background-size: 3px 3px;
  left: 7.1vw;
  z-index: 999;
  top: 10vh;
  border-radius: 10%;
}

.weather-info-box {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  overflow-y: hidden;
}

.weather-info-box .weather-info-box-header {
  top: 0;
  font-size: 1vw;
  width: 100%;
  text-align: center;
  margin: 4px 0px;
  color: #ffffff;
}

#windchart {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
</style>
<style scoped>
.history-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  top: 4vh;
  width: 100%;
}

.history-header img {
  width: 15%;
}

.history-header ul {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.history-header ul li {
  font-size: 1.7rem;
  font-family: "Dirooz";
  font-weight: bold;
  color: #fff;
}

.history-header ul li h4 {
  font-size: 1rem;
  color: #AED581;
  font-weight: bold;
}

.history-header ul li:last-child {
  font-size: 0.7rem;
  color: #fff;
  font-family: "ShabnamBold";
}

.history-header ul li i {
  font-family: "Nazanin";
  font-size: 0.6rem;
  font-style: normal;
  margin-left: 1px;
}

.history-header span {
  margin-right: auto;
  margin-left: 2rem;
  font-size: 2rem;
  color: #ffffff;
  font-weight: bold;
  font-family: "Dirooz";
  border: 1px solid #bbbbbb66;
  padding: 4px;
  border-radius: 7px;
  text-align: center;
}

.history-header span i {
  color: #fff;
  font-style: normal;
  font-size: 1rem;
}

.history-bod {
  display: flex;
  top: 16vh;
  flex-wrap: wrap;
  justify-content: flex-start;
  background-color: #ffffff75;
  margin: 0px 1vw;
  border-radius: 10px;
  padding: 5px;
  height: 20vh;
  overflow-y: auto;
}

.history-bod li {
  flex: 0 0 48%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
  border-bottom: 1px dashed #eceff178;
  margin: 0px 4px;
  padding: 2px 10px;
}

.history-bod li:last-child {
  border: none;
}

.history-bod li h4 {
  font-size: 14px;
  color: #263238;
  font-weight: bold;
}

.history-bod li h3 {
  font-size: 15px;
  color: #FAFAFA;
}

.history-more-info {
  display: flex;
  bottom: 4vh;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 5px 1vw;
  border-radius: 10px;
  padding: 10px;
  height: 21vh;
  overflow-y: auto;
}

.history-more-info li {
  flex: 0 0 46%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
  border-bottom: 1px dashed #ff5722c2;
  margin: 0px 4px;
  padding: 2px 0px;
}

.history-more-info li:last-child {
  border: none;
}

.history-more-info li h4 {
  font-size: 13px;
  color: #ffffff;
}

.history-more-info li h3 {
  font-size: 15px;
  color: #FAFAFA;
}
</style>
<style scoped>
.huorly-forecasts-info-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  top: 4vh;
  width: 100%;
}

.huorly-forecasts-info-header img {
  width: 15%;
}

.huorly-forecasts-info-header ul {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.huorly-forecasts-info-header ul li {
  font-size: 1.7rem;
  font-family: "Dirooz";
  font-weight: bold;
  color: #fff;
}

.huorly-forecasts-info-header ul li h4 {
  font-size: xx-large;
  color: #FF5722;
  font-weight: bold;
}

.huorly-forecasts-info-header ul li:last-child {
  font-size: x-large;
  color: #fff;
  font-family: 'Dirooz';
}

.huorly-forecasts-info-header ul li i {
  font-family: "Nazanin";
  font-size: 0.6rem;
  font-style: normal;
  margin-left: 1px;
}

.huorly-forecasts-info-header span {
  margin-right: auto;
  margin-left: 2rem;
  font-size: 2rem;
  color: #ffffff;
  font-weight: bold;
  font-family: "Dirooz";
  padding: 4px;
  text-align: center;
}

.huorly-forecasts-info-header span i {
  color: #fff;
  font-style: normal;
  font-size: 1rem;
}

.huorly-forecasts-info-bod {
  display: flex;
  top: 16vh;
  flex-wrap: wrap;
  justify-content: flex-start;
  background-color: #ffffffe6;
  margin: 15px 20px;
  border-radius: 5px;
  padding: 5px;
  height: 23vh;
  overflow-y: auto;
}

.huorly-forecasts-info-bod li {
  flex: 0 0 48%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
  border-bottom: 1px dashed #607d8b3d;
  margin: 0px 4px;
  padding: 2px 12px;
}

.huorly-forecasts-info-bod li:last-child {
  border: none;
}

.huorly-forecasts-info-bod li h4 {
  font-size: smaller;
  color: #607D8B;
  font-weight: bold;
}

.huorly-forecasts-info-bod li h3 {
  font-size: medium;
  color: #FF5722;
  font-weight: bold;
}

.huorly-forecasts-date {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex-grow: 1;
}

.huorly-forecasts-date ul {
  margin: 0px;
  padding: 0px;
  display: flex;
  gap: 3px;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  align-content: space-around;
  padding: 2px;
}

.huorly-forecasts-date ul li {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #0000009c;
  border-radius: 15px;
  box-shadow: 0px 4px 8px -3px #ECEFF1;
  padding: 2px;
  border: 2px solid #bbb;
  cursor: pointer;
}

.huorly-forecasts-date ul li:hover {
  background-color: rgb(255 87 34 / 57%);
  border: 2px solid #FF5722;
}

.huorly-forecasts-date ul li.active {
  background-color: rgb(255 87 34 / 57%);
  border: 2px solid #FF5722;
}

.huorly-forecasts-date ul li h4 {
  color: #fff;
  font-size: 14px;
  direction: ltr;
}
</style>
<style scoped>
.daily-box {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0px 5px;
}

.daily-info {
  display: flex;
  flex-direction: column;
}

.daily-info-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.daily-info-header h4 {
  color: #FF5722;
  direction: ltr;
  font-size: x-large;
  padding: 0px 15px;
  font-weight: bold;
}

.daily-info-header hr {
  flex-grow: 1;
  background-color: #ffffff69;
}

.daily-info-header ul {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 9vw;
  margin: 0px 8px;
  list-style: none;
  padding: 0px;
}

.daily-info-header ul li {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.daily-info-header ul li h5 {
  color: #fff;
  flex-grow: 1;
  text-align: right;
  font-size: small;
  font-family: 'Gandom';
}

.daily-info-header ul li i {
  color: #FF5722;
  margin-right: auto;
  margin-left: 7px;
  font-weight: bold;
  font-size: large;
  direction: ltr;
  font-style: normal;
}

.daily-info-body {
  display: flex;
  flex-wrap: wrap;
  margin: 5px;
  padding: 5px;
  list-style: none;
  background-color: #ffffffd4;
  border-radius: 5px;
  box-shadow: 0px 0px 7px 2px #fff;
}

.daily-info-body li {
  flex: 0 0 48%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 5px;
  margin: 0px 5px;
  border-bottom: 1px dashed #607d8b61;
}

.daily-info-body li:last-child {
  border-bottom: none;
}

.daily-info-body li h5 {
  color: #607D8B;
  font-size: smaller;
  font-weight: bold;
  font-family: 'Dirooz';
}

.daily-info-body li i {
  color: #FF5722;
  font-style: normal;
  font-weight: bold;
  direction: ltr;
}


.daily-detail {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 2vh;
}

.today {
  flex: 0 0 48%;
  position: relative;
  border: 3px solid #fff;
  margin: 5px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.today span {
  position: absolute;
  right: 36%;
  top: -2vh;
  background-color: #fff;
  border-radius: 3px;
  padding: 1px 1vw;
}

.today span svg path {
  fill: #000
}

.tonight {
  flex: 0 0 48%;
  position: relative;
  border: 3px solid #000;
  margin: 5px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.tonight span {
  position: absolute;
  right: 36%;
  top: -2vh;
  background-color: #000;
  border-radius: 3px;
  padding: 1px 1vw;
}

.tonight span svg path {
  fill: #fff
}

.daily-detail-box {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}

.daily-detail-box-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-top: 2vh;
  height: 6vh;
}


.daily-detail-box-header h4 {
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: large;
  flex-grow: 1;
}

.daily-detail-box-header h4 small {
  color: #B0BEC5;
  font-size: x-small;
}

.daily-detail-box ul {
  padding: 1px;
  margin: 5px;
  list-style: none;
  flex-grow: 1;
  overflow-y: auto;
  height: 21vh;
  background-color: #ffffffbd;
  border-radius: 0px 0px 10px 10px;
}

.daily-detail-box ul li {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 3px 4px;
  padding: 2px 5px;
  border-bottom: 1px dashed #607D8B;
}

.daily-detail-box ul li:last-child {
  border: none;
}

.daily-detail-box ul li h4 {
  color: #37474F;
  font-size: small;
}

.daily-detail-box ul li h3 {
  color: #FF5722;
  direction: ltr;
  font-size: small;
  font-weight: bold;
}
</style>